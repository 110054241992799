import React from 'react'
import { Stack, Text, DocumentCard, DocumentCardPreview, DocumentCardTitle, ImageFit } from '@fluentui/react'

// const DocumentCardActivityPeople = [{ name: 'Annie Lindqvist', profileImageSrc: TestImages.personaFemale }];

export const RastaStacks: React.FunctionComponent = (props: any) => {
  // interface TestcaseData { testcases: [{
  //   dbId: string
  //   tcVersionMainMajor: string
  //   tcVersionMainMinor: string
  //   tcVersionLabMinor: string
  //   tcVersionLabCampaign: string
  //   tcApplicability: string
  //   tcCoverage: string
  //   focus: string
  //   name: string
  //   description: string
  //   remarks: string
  //   type: string
  //   siteData: string
  //   author: string
  //   state: string
  // }]}
  // const { loading, error, data } = useQuery<TestcaseData>(TESTCASES);

  // if (loading) return <p>Loading...</p>;
  // if (error || !data) return <Text color="red" styles={{root: {margin: '20px'}}}>An error occurred. :(</Text>;


  return (
    <Stack>
      <Text variant="xxLarge" styles={{root: {padding: '20px'}}}>
        RaSTA Stacks
      </Text>
      <Stack
        wrap
        styles={{root: {margin: '20px'}}}
        horizontal
        tokens={{
          childrenGap: 20,
        }}
      >
        <DocumentCard>
          <DocumentCardPreview previewImages={[
            {
              name: 'dbnetz',
              previewImageSrc: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Db-netze.svg/1302px-Db-netze.svg.png',
              imageFit: ImageFit.contain,
              width: 318,
              height: 196,
            },
          ]} />
          <DocumentCardTitle title={'DB Netz'} shouldTruncate />
        </DocumentCard>
        <DocumentCard>
          <DocumentCardPreview previewImages={[
            {
              name: 'brunel',
              previewImageSrc: 'https://upload.wikimedia.org/wikipedia/commons/d/dc/Brunel_logo.png',
              imageFit: ImageFit.contain,
              width: 318,
              height: 196,
            },
          ]} />
          <DocumentCardTitle title={'Brunel'} shouldTruncate />
        </DocumentCard>
        <DocumentCard>
          <DocumentCardPreview previewImages={[
            {
              name: 'uni-passau',
              previewImageSrc: 'https://www.uni-passau.de/typo3conf/ext/upatheme/Resources/Public/img/logo.svg',
              imageFit: ImageFit.contain,
              width: 318,
              height: 196,
            },
          ]} />
          <DocumentCardTitle title={'Universität Passau'} shouldTruncate />
        </DocumentCard>
      </Stack>
    </Stack>
  )
}
