import { Stack } from '@fluentui/react';
import React from 'react'
import { InterlockingUI, Topology } from 'interlocking-ui';

export class Fahrzeugparade extends React.Component<{}, {topo?: Topology, webSocket?: WebSocket, interlockingState?: {[key:string]: string}}> {

  componentDidMount() {

    fetch('https://lab.eulynx.live/device-io/scheibenberg-scenarios-4573-demoday/interlocking/Topology')
      .then(response => response.json())
      .then(data => this.setState({topo: data as Topology}));

      const ws = new WebSocket('wss://lab.eulynx.live/device-io/scheibenberg-scenarios-4573-demoday/interlocking/ws');
      this.setState({
          webSocket: ws,
      })
      ws.onopen = (event) => {
          console.log("connected");
      }
      ws.onerror = (error) => {
          console.error(error);
      }
      ws.onmessage = (event) => {
          this.receiveMessage(event);
      };
  }

  receiveMessage(event: MessageEvent) {
    if (typeof event.data !== 'string') {
        return;
    }
    const state = JSON.parse(event.data) as {
      "id": string | null,
      "states": {[key:string]: string},
      "validTransitions": null
    };
    this.setState({interlockingState: state.states});
  }

  render() {
    if (this.state && this.state.topo) {
      return (
        <Stack horizontal verticalFill>
          <InterlockingUI topology={this.state.topo} showDeviceIO={true} interlockingState={this.state.interlockingState} placement={{signals: {}, points: {}, edges: {}}}/>
        </Stack>
      );
    }
    return (<></>);
  }
}
