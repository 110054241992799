import { gql } from '@apollo/client';

export const DYNAMIC_TEST_RUN_LOG_SUBSCRIPTION = gql`
subscription DynamicTestRunLogSubscription($testRunId: String!) {
  onLogLineReceived(testRunId: $testRunId) {
    job
    log
  }
}
`;

export const DYNAMIC_TEST_RUN_END_SUBSCRIPTION = gql`
subscription DynamicTestRunLogSubscription($testRunId: String!) {
  onTestRunEnd(testRunId: $testRunId)
}
`;
