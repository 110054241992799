import * as React from "react"
import { DetailsList, DetailsListLayoutMode, Dropdown, PrimaryButton, Stack, Text, Selection, IDropdownOption, IDropdownStyles, Spinner, SpinnerSize, Toggle } from "@fluentui/react"
import { CREATE_TEST_CASE, PlanningProjectsData, PLANNING_PROJECTS } from "../GraphQL/Queries";
import { useMutation, useQuery } from "@apollo/client";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface PlanningProjectSelectorProps {
  selectedKey: string | number | string[] | number[] | null | undefined;
  onChange?: (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption, index?: number) => void,
}

const PlanningProjectSelector: React.FunctionComponent<PlanningProjectSelectorProps> = (props: PlanningProjectSelectorProps) => {
  const { loading, error, data } = useQuery<PlanningProjectsData>(PLANNING_PROJECTS);

  if (loading) return <Spinner styles={{root: {padding: '20px'}}} size={SpinnerSize.large} />;
  if (error || !data) return <Text color="red" styles={{root: {margin: '20px'}}}>An error occurred. :(</Text>;

  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

  return (
    <Dropdown
      label="Planning Project"
      selectedKey={props.selectedKey}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={props.onChange}
      placeholder="Select an option"
      options={data.planProProjects.map(x => ({key: x.id, text: x.name}))}
      styles={dropdownStyles}
    />);
}

export const _TestSpecializationCreate: React.FunctionComponent<RouteComponentProps> = (props) => {
  const columns = [
    { key: 'id', name: 'ID', fieldName: 'id', minWidth: 250, isResizable: true },
    { key: 'author', name: 'Author', fieldName: 'author', minWidth: 100, isResizable: true },
    { key: 'description', name: 'Description', fieldName: 'description', minWidth: 250, isResizable: true, isMultiline: true },
    { key: 'date', name: 'Date', fieldName: 'date', minWidth: 100, isResizable: true },
  ];

  const displayItems = [{
    id: 'TC_F01.B06.E.02.01.01',
    author: 'KoljaMuchow',
    description: 'Simulation der aufgefahrenen Weiche <<<P_P_Bez>>> durchführen;\nWeiche <<<P_P_Bez>>> mit Funktion AWU umstellen; Weiche erreicht Ordnungsstellung',
    date: '17.06.2020'
  }]

  const [selectedProject, setSelectedProject] = React.useState<IDropdownOption>();
  const [testcaseExecution, setTestcaseExecution] = React.useState<boolean>(true);
  const [selectedTestcase, setSelectedTestcase] = React.useState<boolean>();
  const [selectedParameter, setSelectedParameter] = React.useState<IDropdownOption>();

  const selection = new Selection({
    onSelectionChanged: () => setSelectedTestcase(true)
  });

  const [createTestCase,] = useMutation(CREATE_TEST_CASE);

  return (
    <Stack>
      <Text variant="xxLarge" styles={{ root: { padding: '20px' } }}>
        New Test Specialization
      </Text>
      <Stack
        styles={{ root: { padding: 20 } }}
        tokens={{
          childrenGap: 15,
        }}
      >
        <PlanningProjectSelector
          selectedKey={selectedProject ? selectedProject.key : undefined}
          onChange={(event, option) => setSelectedProject(option)} />

        <Stack>
          <Toggle label="Test Case Execution" defaultChecked onText="Enabled" offText="Disabled" onChange={(event, checked) => setTestcaseExecution(checked || false)} />
          {testcaseExecution &&
            <DetailsList
              items={displayItems}
              columns={columns}
              setKey="set"
              styles={{ root: { width: '100%' } }}
              layoutMode={DetailsListLayoutMode.justified}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              selection={selection}
            />
          }
        </Stack>

        {testcaseExecution && selectedTestcase &&
          <Stack>
            <Text variant="xLarge">
              Bind Test Parameters
            </Text>
            <Stack horizontal>
              <Dropdown
                placeholder="Select an option"
                label="P_P_Bez"
                options={[
                  { key: '99W1', text: '99W1' },
                  { key: '99W2', text: '99W2' },
                  { key: '99W3', text: '99W3' },
                  { key: '99W4', text: '99W4' },
                ]}
                styles={{ dropdown: { width: 300 }}}
                onChange={(event, option) =>  { setSelectedParameter(option) }}
              />
            </Stack>
          </Stack>
        }

        <Stack horizontal>
          <PrimaryButton text="Create Test Specialization" disabled={testcaseExecution && (!selectedParameter || !selectedProject)} onClick={async () => {
            await createTestCase({
              variables: {
                id: (testcaseExecution && selectedTestcase) ? 'TC_F01.B06.E.02.01.01' : null,
                planningProjectId: selectedProject?.key,
                parameters: (testcaseExecution && selectedTestcase) ? { 'P_P_Bez': selectedParameter?.key } : null
              }
            });
            setSelectedProject(undefined);
            setTestcaseExecution(true);
            setSelectedTestcase(false);
            setSelectedParameter(undefined);
            props.history.replace('/test-specializations');
          }} />
        </Stack>
      </Stack>
    </Stack>
  )
}

export const TestSpecializationCreate = withRouter(_TestSpecializationCreate);
