import React, { CSSProperties } from 'react'

import { ISpinnerStyles, IStackTokens, ITextStyles, SharedColors, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';

import { DynamicTest } from '../DynamicTest'
import { PeerConfiguration } from '../DynamicTestRun'
import { Accordion } from '../Accordion';

export interface ConfigurationDisplayProps {
    loading: boolean,
    error: boolean,
    testCase: DynamicTest,
    peerConfigurations: PeerConfiguration[],
}

const spinnerStyle: Partial<ISpinnerStyles> = {
    root: {
        margin: '0 auto',
        width: '100%',
    },
};

const errorTextStyle: Partial<ITextStyles> = {
    root: {
        color: SharedColors.redOrange10
    },
};

const accordionContainerStyle: CSSProperties = {
    padding: '1rem',
};

const wrapStackTokens: IStackTokens = {
    childrenGap: '1rem'
};

export const ConfigurationDisplay: React.FunctionComponent<ConfigurationDisplayProps> = (props) => {
    const { loading, error, testCase, peerConfigurations } = props;

    const cannotRender = !testCase.peers || !peerConfigurations || peerConfigurations.length !== testCase.peers.length;
    if (cannotRender) {
        return <Text styles={errorTextStyle}>Configuration is invalid and could not be displayed.</Text>
    }

    const peerConfigs = testCase.peers.map((dynamicTestPeer, idx) => (
        {
            peer: dynamicTestPeer,
            configuration: peerConfigurations[idx],
        }
    ));

    return (
        <>
            { loading && <Spinner styles={spinnerStyle} size={SpinnerSize.large} /> }
            { error && <Text styles={errorTextStyle}>Error loading configuration.</Text> }
            { !loading && !error && (
                <>
                    <Accordion title="Interlocking" defaultCollapsed>
                        <div style={accordionContainerStyle}>
                            <Text><b>Container Image:</b> {testCase.interlocking.image}</Text>
                        </div>
                    </Accordion>
                    { peerConfigs.map((config) => (
                        <Accordion key={config.peer.name} title={`Peer ${config.peer.name}`} defaultCollapsed>
                            <Stack
                                horizontal
                                style={accordionContainerStyle}
                                wrap
                                tokens={wrapStackTokens}
                            >
                                <Text><b>Name</b>: {config.peer.name}</Text>
                                <Text><b>Local ID</b>: {config.peer.localId}</Text>
                                <Text><b>Local RaSTA ID</b>: {config.peer.localRastaId}</Text>
                                { config.peer.validDevices && <Text><b>Valid device type</b>: {config.peer.validDevices}</Text> }
                                { !!config.peer.simulatedDevice && config.configuration?.isSimulator && (
                                    <>
                                        <Text><b>Is Simulated</b>: Yes</Text>
                                        <Text><b>Simulator Container Image</b>: {config.peer.simulatedDevice.image}</Text>
                                    </>
                                ) }
                                { !!config.peer.realDevice && !config.configuration?.isSimulator && (
                                    <>
                                        <Text><b>Is Simulated</b>: No</Text>
                                        <Text><b>Used Device ID</b>: {config.peer.realDevice.id}</Text>
                                        <Text><b>Connection Type</b>: {config.peer.realDevice.connectionType}</Text>
                                    </>
                                )}
                                { config.peer.validDevices && !config.configuration?.isSimulator && !config.configuration.deviceId && (
                                    <>
                                        <Text><b>Is Simulated</b>: No</Text>
                                        <Text><b>Used Device ID</b>: {config.configuration.deviceId}</Text>
                                    </>
                                )}
                            </Stack>
                        </Accordion>
                    ))}
                </>
            )}
        </>
    );
}
