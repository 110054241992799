import React, { FunctionComponent, CSSProperties, useState, useRef } from 'react'
import { ISpinnerStyles, mergeStyleSets, NeutralColors, Spinner, SpinnerSize } from '@fluentui/react'
import { IStyleSet, Text, ITextStyles, Pivot, PivotItem, IPivotStyles } from '@fluentui/react';

export interface DynamicTestRunIOFrameProps {
    frames: {
        jobName: string,
        url: string,
    }[],
    loading?: boolean,
    error?: boolean,
}

const containerStyle: CSSProperties = {
    marginBottom: '1rem',
    width: '100%',
}

const frameStyle: CSSProperties = {
    width: '100%',
    height: '700px',
    border: 'none',
};


const spinnerStyle: Partial<IStyleSet<ISpinnerStyles>> = {
    root: {
        width: '100%',
        margin: '0 auto',
    },
};

const noControlsStyle: Partial<IStyleSet<ITextStyles>> = {
    root: {
        color: NeutralColors.gray120,
        display: 'block',
        width: '100%',
        textAlign: 'center',
    },
};

const pivotStyle: Partial<IStyleSet<IPivotStyles>> = {
    root: {
        width: '100%',
    }
}

const styles = mergeStyleSets({
    iframe: frameStyle,
});

export const ControlFrames: FunctionComponent<DynamicTestRunIOFrameProps> = (props) => {
    const { frames, loading, error } = props;

    const [controlServiceConnected, setControlServiceConnected] = useState(false);
    const [reloadCtr, setReloadCtr] = useState(0);
    const [selectedTabNumber, setSelectedTabNumber] = useState<number>(0);
    const tabRef = useRef<string>(selectedTabNumber.toString());
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const shouldStopRef = useRef<boolean>(false);

    React.useEffect(() => {
        // Cancel on unmount.
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                timeoutRef.current = undefined;
            }
        }
    }, []);

    // Wait until connection succeeds.
    const checkConnection = React.useCallback(async () => {
        if (controlServiceConnected) {
            return;
        }
        const frameNumber = parseInt(tabRef.current || '0', 10);
        const firstURL = frames[frameNumber]?.url
        if (!firstURL) {
            return;
        }
        try {
            const response = await fetch(firstURL)
            // Expect a 1xx / 2xx / 3xx status code.
            if (response.status > 400) {
                setControlServiceConnected(false);
                // Only retry when tab didn't change.
                if (!shouldStopRef.current) {
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    timeoutRef.current = setTimeout(checkConnection, 1000);
                }
                return;
            }
            setControlServiceConnected(true);
            setReloadCtr(reloadCtr + 1);
        } catch {
            setControlServiceConnected(false);
        }
    }, [controlServiceConnected, frames, reloadCtr])

    React.useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = undefined;
        }
        if (frames.length > 0) {
            shouldStopRef.current = false;
            tabRef.current = selectedTabNumber.toString();
            timeoutRef.current = setTimeout(checkConnection, 1000);
        } else {
            shouldStopRef.current = true;
        }
    }, [frames, selectedTabNumber, checkConnection]);


    return (
        <div style={containerStyle}>
            { loading && <Spinner styles={spinnerStyle} size={SpinnerSize.large} /> }
            { error && <Text styles={noControlsStyle} variant='large'>No Control Frames</Text> }

            { frames.length <= 0 && <Text styles={noControlsStyle} variant='large'>No Control Frames</Text> }
            { frames.length > 0 && (
                <Pivot
                    aria-label="Control IO Frames"
                    styles={pivotStyle}
                    overflowBehavior='menu'
                    onLinkClick={(tab) => { setSelectedTabNumber(parseInt(tab?.props.itemKey || '0')); setControlServiceConnected(false); }}
                >
                    { frames.map((frame, index) =>
                        <PivotItem
                            key={`c-p-item-${index}`}
                            itemKey={index.toString()}

                            headerText={frame.jobName}
                            headerButtonProps={{
                                'data-order': index,
                                'data-title': frame.jobName,
                            }}
                        >
                            { !controlServiceConnected && <Spinner styles={spinnerStyle} size={SpinnerSize.large} /> }
                            { controlServiceConnected && <iframe scrolling="yes" key={`ctrl-frame-${reloadCtr}`} title={frame.jobName} className={styles.iframe} src={frame.url} /> }
                        </PivotItem>
                    )}
                </Pivot>
            )}
        </div>
    );
}
