import * as React from 'react'
import { Label, PrimaryButton, Stack, Text, TextField } from '@fluentui/react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PlanningProjectsData, PLANNING_PROJECTS } from '../GraphQL/Queries';

const _PlanningProjectCreate: React.FunctionComponent<RouteComponentProps> = (props) => {
  const { refetch: refetchPlanningProjects } = useQuery<PlanningProjectsData>(PLANNING_PROJECTS);

  const form = React.createRef<HTMLFormElement>();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    await fetch(event.currentTarget.action, {
      method: 'POST',
      body: formData
    });

    await refetchPlanningProjects();

    props.history.replace('/projects');
  };

  return (
    <Stack>
      <Text variant='xxLarge' styles={{ root: { padding: '20px' } }}>
        New Project
      </Text>
      <form onSubmit={handleSubmit} method='POST' action='/planpro' encType='multipart/form-data' ref={form}>
        <Stack styles={{root: { width: 300, padding: '20px' }}}>
          <Label htmlFor={'name'}>Project Name</Label>
          <TextField id={'name'} name={'name'} required />

          <Label htmlFor={'author'}>Author</Label>
          <TextField id={'author'} name={'author'} required />

          <Label htmlFor={'description'}>Description</Label>
          <TextField id={'description'} name={'description'} required />

          <Label htmlFor={'planpro-file'}>PlanPro XML File</Label>
          <input type='file' name='planpro-file' />

          <Label htmlFor={'eulynx-file'}>EULYNX XML File</Label>
          <input type='file' name='eulynx-file' />
        </Stack>
        <PrimaryButton text='Create Project' styles={{root: {margin: '20px'}}} onClick={() => form.current?.requestSubmit()} />
      </form>
    </Stack>
  )
}

export const PlanningProjectCreate = withRouter(_PlanningProjectCreate);
