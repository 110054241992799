import React from 'react'
import { Spinner, SpinnerSize, Stack, Text } from '@fluentui/react'
import { InterlockingUI, OverallPlacement, Topology } from 'interlocking-ui';
import { RouteComponentProps } from 'react-router-dom';

interface IReactRouterParams {
  projectId: string;
}

export class PlanningProjectView extends React.Component<RouteComponentProps<IReactRouterParams>, {
  topology?: Topology,
  placement?: OverallPlacement
}> {
  componentDidMount() {
    const fetchData = async () => {
      const response = await fetch(`/planpro/topology/${this.props.match.params.projectId}`, { method: 'POST' });
      const data = await response.json() as Topology;
      this.setState({topology: data});

      const placementResponse = await fetch(`/planpro/placement/${this.props.match.params.projectId}`, { method: 'POST' });
      const placementData = await placementResponse.json() as OverallPlacement;
      this.setState({placement: placementData});
    }
    fetchData();
  }

  render() {
    const topology = this.state ? this.state.topology : null;
    const placement = this.state ? this.state.placement : null;

    return (
      <Stack>
        <Text variant="xxLarge" styles={{root: {padding: '20px'}}}>
          Planning Project
        </Text>
        {(!topology || !placement) && <Spinner styles={{root: {padding: '20px'}}} size={SpinnerSize.large} />}
        {topology && placement && <InterlockingUI topology={topology} showDeviceIO={false} placement={placement} />}
      </Stack>
    );
  }
}
