import React from 'react'
import { Stack, Text, DocumentCard, DocumentCardPreview, DocumentCardTitle, ImageFit, CommandBar, Spinner, SpinnerSize } from '@fluentui/react'

import { useQuery } from '@apollo/client';
import { withRouter } from 'react-router-dom';

import { LOCATIONS } from './GraphQL/Queries';

const _Locations: React.FunctionComponent = (props: any) => {
  interface LocationData { locations: [{
    name: string
    image: string
  }]}
  const { loading, error, data } = useQuery<LocationData>(LOCATIONS);

  const renderLocations = () => {
    if (loading) return <Spinner styles={{root: {padding: '20px'}}} size={SpinnerSize.large} />;
    if (error || !data) return <Text color="red" styles={{root: {margin: '20px'}}}>An error occurred. :(</Text>;
    return (
      <Stack
        wrap
        styles={{root: {margin: '20px'}}}
        horizontal
        tokens={{
          childrenGap: 20,
        }}
      >
        {data.locations.map(x =>
          <DocumentCard key={x.name}>
            <DocumentCardPreview previewImages={[
              {
                previewImageSrc: x.image,
                imageFit: ImageFit.cover,
                width: 318,
                height: 196,
              },
            ]} />
            <DocumentCardTitle title={x.name} shouldTruncate />
          </DocumentCard>
        )}
      </Stack>
    );
  }

  return (
    <Stack>
      <Text variant="xxLarge" styles={{root: {padding: '20px'}}}>
        Locations
      </Text>
      <CommandBar
        items={[
          {
            key: 'new',
            text: 'New',
            iconProps: { iconName: 'Add' },
            onClick: () => { props.history.push('/locations/add') }
          }
        ]}
      />
      { renderLocations() }
    </Stack>
  )
}

export const Locations = withRouter(_Locations);
