import React from 'react'
import { Label, PrimaryButton, Stack, Text, TextField } from '@fluentui/react'
import { gql, useMutation } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LOCATIONS } from './GraphQL/Queries';

const ADD_LOCATION = gql`
  mutation AddLocation($name: String!, $image: String!) {
    addLocation(location: { name: $name, image: $image }) {
      name
    }
  }
`;

const _AddLocation: React.FunctionComponent<RouteComponentProps> = (props) => {
  const [nameTextFieldValue, setNameTextFieldValue] = React.useState('');
  const [imageTextFieldValue, setImageTextFieldValue] = React.useState('');
  const onChangeNameTextFieldValue = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setNameTextFieldValue(newValue || '');
    },
    [],
  );
  const onChangeImageTextFieldValue = React.useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      setImageTextFieldValue(newValue || '');
    },
    [],
  );

  const [addLocation,] = useMutation(ADD_LOCATION, { refetchQueries: [ { query: LOCATIONS } ] });

  return (
    <Stack>
      <Text variant="xxLarge" styles={{root: {padding: '20px'}}}>
        Add Location
      </Text>
      <div>
        <Stack styles={{root: { width: 300, padding: '20px' }}}>
          <Label htmlFor={'name'}>Location Name</Label>
          <TextField id={'name'} required value={nameTextFieldValue} onChange={onChangeNameTextFieldValue} />

          <Label htmlFor={'image'}>Image URL</Label>
          <TextField id={'image'} required value={imageTextFieldValue} onChange={onChangeImageTextFieldValue} />
        </Stack>
        <PrimaryButton text="Save" styles={{root: {margin: '20px'}}} onClick={ async () => {
          await addLocation({ variables: { name: nameTextFieldValue, image: imageTextFieldValue } });
          setNameTextFieldValue('');
          setImageTextFieldValue('');
          props.history.replace('/locations');
        }} />
      </div>
    </Stack>
  )
}

export const AddLocation = withRouter(_AddLocation);
