import React from 'react'
import { withRouter } from "react-router-dom";
import { DetailsListLayoutMode, DetailsList, Stack, Text, CommandBar, Spinner, SpinnerSize, IColumn, DefaultButton, Selection, SelectionMode } from '@fluentui/react'
import { gql, useMutation, useQuery } from '@apollo/client';
import { PlanningProject, PlanningProjectsData, PLANNING_PROJECTS } from '../GraphQL/Queries';

const columns = [
  { key: 'name', name: 'Name', fieldName: 'name', minWidth: 250, isResizable: true },
  { key: 'download', name: 'Download', fieldName: 'id', minWidth: 250, isResizable: true },
  { key: 'author', name: 'Author', fieldName: 'author', minWidth: 250, isResizable: true },
  { key: 'description', name: 'Description', fieldName: 'description', minWidth: 250, isResizable: true, isMultiline: true },
  { key: 'version', name: 'Version', fieldName: 'version', minWidth: 100, isResizable: true },
];


const REMOVE_PROJECT = gql`
  mutation removeProject($projectId: String!) {
    removeProject(projectId: $projectId) {
      id
    }
  }
`;

const _PlanningProjects: React.FunctionComponent = (props: any) => {
  const { loading, error, data } = useQuery<PlanningProjectsData>(PLANNING_PROJECTS);
  const [ removeProject,] = useMutation(REMOVE_PROJECT, { refetchQueries: [ { query: PLANNING_PROJECTS } ] });
  const [selectedItem, setSelectedItem] = React.useState<PlanningProject | undefined>(undefined)
  const selection = new Selection({
      onSelectionChanged: () => {
          setSelectedItem(selection.getSelection()[0] as PlanningProject)
      }
  });

  if (loading) return <Spinner styles={{root: {padding: '20px'}}} size={SpinnerSize.large} />;
  if (error || !data) return <Text color="red" styles={{root: {margin: '20px'}}}>An error occurred. :(</Text>;

  function _renderItemColumn(item: any, index?: number, column?: IColumn) {
    if (column) {
      const fieldContent = item[column.fieldName as keyof any] as string;

      switch (column.key) {
        case 'download':
          return (<>
            <form method="post" action={`/planpro/download/${fieldContent}/planpro`}>
              <DefaultButton type="submit">PlanPro</DefaultButton>
            </form>
            <form method="post" action={`/planpro/download/${fieldContent}/eulynx`}>
              <DefaultButton type="submit">EULYNX</DefaultButton>
            </form>
          </>);

        default:
          return <span>{fieldContent}</span>;
      }
    }
  }

  return (
    <Stack>
      <Text variant="xxLarge" styles={{root: {padding: '20px'}}}>
        Projects
      </Text>
      <CommandBar
          items={[
            {
                key: 'new',
                text: 'New',
                iconProps: { iconName: 'Add' },
                onClick: () => { props.history.push('/projects/add') }
            },
            {
                key: 'delete',
                text: 'Delete',
                iconProps: { iconName: 'Delete' },
                disabled: !selectedItem,
                onClick: () => { selectedItem && removeProject({ variables: { projectId: selectedItem.id } }) }
            },
          ]}
      />
      <DetailsList
          items={data.planProProjects}
          columns={columns}
          onRenderItemColumn={_renderItemColumn}
          setKey="set"
          styles={{root: {width: '100%'}}}
          layoutMode={DetailsListLayoutMode.justified}
          selection={selection}
          selectionMode={SelectionMode.single}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="Row checkbox"
          onItemInvoked={(item: any) => {
            props.history.push(`/projects/${item.id}`);
          }}
        />
    </Stack>
  )
}

export const PlanningProjects = withRouter(_PlanningProjects);
