import React, { CSSProperties } from "react"
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import {
    DefaultButton, getTheme, IButtonStyles, MessageBar, Link,
    MessageBarType, PrimaryButton, SharedColors, Spinner, SpinnerSize, Stack, Text
} from "@fluentui/react"

import { DynamicTestCaseData } from "../DynamicTest";
import { LogDisplay } from './LogDisplay'
import { SPECIFIC_DYNAMIC_TEST_RUN, DYNAMIC_TEST_WITH_ID, DYNAMIC_TEST_RUN_LOGS, DYNAMIC_TEST_RUN_CONTROL_URLS, DYNAMIC_TEST_RUN_GQL_CONTENT } from "../../GraphQL/Queries";
import { DynamicTestRun, DynamicTestRunData, DynamicTestRunLogs, LogMessages, LogMessage, DynamicTestRunControlUrls } from "../DynamicTestRun";
import { DYNAMIC_TEST_RUN_END_SUBSCRIPTION, DYNAMIC_TEST_RUN_LOG_SUBSCRIPTION } from "../../GraphQL/Subscriptions";
import { ControlFrames } from "./ControlFrames";
import { ConfigurationDisplay } from "./ConfigurationDisplay";

const CANCEL_TEST_RUN = gql`
mutation CancelDynamicTestCase($testRunId: String!) {
    cancelDynamicTestRun(testRunId: $testRunId) {
        ${DYNAMIC_TEST_RUN_GQL_CONTENT}
    }
}
`;

interface CancelDynamicTestRunResponse {
    cancelDynamicTestRun: DynamicTestRun,
}

const theme = getTheme();

const cancelButtonStyles: IButtonStyles = {
    root: {
        backgroundColor: SharedColors.redOrange10,
        color: theme.palette.white,
        borderColor: SharedColors.redOrange10,
    },
    rootHovered: {
        backgroundColor: SharedColors.red10,
        color: theme.palette.white,
        borderColor: SharedColors.red10,
    },
    rootPressed: {
        backgroundColor: SharedColors.red20,
        color: theme.palette.white,
        borderColor: SharedColors.red20,
    }
}

const greenText: CSSProperties = {
    color: SharedColors.green10,
};

const redText: CSSProperties = {
    color: SharedColors.red10,
};

export const TestSpecializationRunDetails: React.FunctionComponent = () => {
    const { testCaseDbId, testRun: testRunParam } = useParams<{ testCaseDbId: string, testRun: string }>();
    const testRunNumber = parseInt(testRunParam);

    const { loading: testCaseLoading, error: testCaseError, data: testCaseData } = useQuery<DynamicTestCaseData>(DYNAMIC_TEST_WITH_ID, {
        variables: {
            dBId: testCaseDbId,
        }
    });
    const { loading: testRunLoading, error: testRunError, data: testRunData, refetch: refetchTestRun } = useQuery<DynamicTestRunData>(SPECIFIC_DYNAMIC_TEST_RUN, {
        variables: {
            testCaseDbId,
            testRunNumber,
        }
    });
    const loading = testCaseLoading || testRunLoading;
    const error = testCaseError || testRunError;

    const found = testCaseData && testCaseData.dynamicTestCases.length && testRunData && testRunData.dynamicTestRuns.length;
    const testCase = found ? testCaseData!.dynamicTestCases[0] : null;
    const foundTestRun = found ? testRunData!.dynamicTestRuns[0] : undefined;
    const [testRun, setTestRun] = React.useState<DynamicTestRun | undefined>();
    React.useEffect(() => setTestRun(foundTestRun), [foundTestRun]);

    // Cancellation.
    const [canCancel, setCanCancel] = React.useState(testRun?.running ?? false);
    const [cancelError, setCancelError] = React.useState<string>();
    React.useEffect(() => {
        setCanCancel(testRun?.running ?? false);
    }, [testRun]);
    const [cancelTestRunMutation] = useMutation<CancelDynamicTestRunResponse>(CANCEL_TEST_RUN, {
        variables: {
            testRunId: testRun?.id,
        }
    });
    const cancelTestRun = async () => {
        setCanCancel(false);
        try {
            const result = await cancelTestRunMutation();
            if (result.errors) {
                setCanCancel(true);
            } else if (result.data) {
                setTestRun(result.data.cancelDynamicTestRun);
            }
        } catch (ex) {
            setCanCancel(testRun?.running ?? false);
            if (ex instanceof Error) {
                setCancelError(`${ex.name}: ${ex.message}`);
                return;
            }
            setCancelError(`${ex}`);
        }
    }

    // Logs.
    const [testRunLogs, setTestRunLogs] = React.useState<LogMessages>({});
    const appendToLogs = (job: string, logLines: string[]) => {
        if (!job || !logLines) {
            return;
        }
        // Filter null lines.
        logLines = logLines.filter((line) => !!line);
        if (!!testRunLogs[job]) {
            // Already exists.
            testRunLogs[job] = testRunLogs[job].concat(logLines);
        } else {
            // Create new array.
            testRunLogs[job] = logLines;
        }
        setTestRunLogs({...testRunLogs});
    }

    useQuery<DynamicTestRunLogs>(DYNAMIC_TEST_RUN_LOGS, {
        variables: {
            testRunId: testRun?.id,
        },
        skip: !testRun?.id,

        onCompleted: (data) => {
            if (!!data.dynamicTestRunLogs && data.dynamicTestRunLogs.logs) {
                data.dynamicTestRunLogs.logs.forEach((l) => {
                    appendToLogs(l.key, l.value);
                });
            }
        }
    });

    useSubscription<LogMessage>(DYNAMIC_TEST_RUN_LOG_SUBSCRIPTION, {
        variables: {
            testRunId: testRun?.id || "",
        },
        shouldResubscribe: true,
        skip: !testRun?.id,
        onSubscriptionData: (response) => {
            if (response.subscriptionData.error) {
                console.error('Error fetching logs:', response.subscriptionData.error);
            }
            if (!response.subscriptionData.data) {
                return;
            }
            // Append new logs.
            const {job, log} = response.subscriptionData.data.onLogLineReceived;
            appendToLogs(job, log.split('\n'));
        },
    });

    // Subscription to detect test run end.
    useSubscription<Boolean>(DYNAMIC_TEST_RUN_END_SUBSCRIPTION, {
        variables: {
            testRunId: testRun?.id || "",
        },
        shouldResubscribe: true,
        skip: !testRun?.id || !testRun?.running,
        onSubscriptionComplete: () => {
            refetchTestRun()
        },
    });

    // Control Frames.
    const { loading: controlUrlsLoading, error: controlUrlsError, data: controlUrlsData } = useQuery<DynamicTestRunControlUrls>(DYNAMIC_TEST_RUN_CONTROL_URLS, {
        variables: {
            testRunId: testRun?.id || "",
        },
        skip: !testRun?.id || !testRun?.running,
    });

    const controlFrames = testRun?.running && controlUrlsData?.dynamicTestRunControlUrls
        ? controlUrlsData!.dynamicTestRunControlUrls!.map((u) => ({jobName: u.job, url: u.url}))
        : [];


    // Data loading.
    if (!testRunNumber || isNaN(testRunNumber) || testRunNumber < 0) {
      return (
          <Text color="red" styles={{ root: { margin: "20px" } }}>An error occurred. :(</Text>
      );
    }

    return (
        <Stack
            styles={{ root: { padding: 20, flex: '1', flexGrow: '1', }}}
            tokens={{
                childrenGap: 15,
            }}
        >
            {loading &&
                <Spinner styles={{ root: { padding: "20px" } }} size={SpinnerSize.large} />}
            {!loading && (error || !testCase || !testRunData) &&
                <Text styles={{ root: { margin: "20px" } }}>An error occurred. :(</Text>}
            {!loading && !error && testCase && testRun &&
                <>
                    <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 20 }}>
                        <Text variant="xxLarge">
                            Test&nbsp;Case:&nbsp;{testCase.name} ({testCase.id})
                        </Text>
                        {testRun.running && <Spinner size={SpinnerSize.large} />}
                    </Stack>

                    <Text variant="large">
                        Test Specialization Run #{testRun.testRunNumber} ({!testRun.running ? "not " : ""}running, started {new Date(testRun.startDate).toLocaleString()})
                    </Text>

                    { testRun.exitCode !== null && (
                        <Text variant="xLarge">
                            Exit Code: {testRun.exitCode} ({testRun.exitCode === 0 ? <span style={greenText}>Success</span> : <span style={redText}>Failed</span>})
                        </Text>
                    )}

                    <Text variant="xLarge">Control</Text>
                    <ControlFrames loading={controlUrlsLoading} error={!!controlUrlsError} frames={controlFrames} />

                    <Text variant="xLarge">Used Specialization Configuration</Text>
                    <ConfigurationDisplay
                        loading={testCaseLoading || testRunLoading}
                        error={!!testCaseError || !!testRunError}
                        testCase={testRun.testCase}
                        peerConfigurations={testRun.configurations}
                    />

                    <Text variant="xLarge">Logs</Text>
                    <LogDisplay logs={testRunLogs} />

                    {
                    // Cancellation button.
                    testRun.running &&
                        <div style={{
                            marginTop: '2rem',
                            marginRight: '1rem',
                        }}>
                            {cancelError &&
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    styles={{
                                        root: {
                                            marginBottom: '0.5rem',
                                        }
                                    }}
                                    onDismiss={() => setCancelError(undefined)}
                                >
                                    {cancelError}
                                </MessageBar>
                            }
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <DefaultButton
                                    text="Cancel"
                                    disabled={!canCancel}
                                    styles={cancelButtonStyles}
                                    iconProps={{ iconName: "StopSolid" }}
                                    onClick={cancelTestRun} />
                            </div>
                        </div>
                    }

                    {
                    // Download Button
                    testRun && !testRun.running &&
                        <div style={{
                            marginTop: '2rem',
                            marginRight: '1rem',
                        }}>

                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}>
                                <Link href={`/captures/${testRun!.id}`} target='_blank' download>
                                    <PrimaryButton
                                        text="Download Packet Captures"
                                        iconProps={{ iconName: "Download" }} />
                                </Link>
                            </div>
                        </div>
                    }
                </>
            }
        </Stack>
    )
}
