import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { mergeStyles, initializeIcons } from '@fluentui/react';
import { ApolloClient, ApolloProvider, from, HttpLink, InMemoryCache } from '@apollo/client';
import { split } from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({
  uri: '/graphql'
});

const wsLink = new WebSocketLink({
  uri: ((window.location.protocol === "https:") ? "wss://" : "ws://") + window.location.host + "/graphql",
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const authLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (!err.extensions) continue;
      switch (err.extensions.code) {
        case 'AUTH_NOT_AUTHENTICATED':
            window.location.href = '/Account/Login';
            break;
      }
    }
  }
});

const client = new ApolloClient({
  link: from([authLink, splitLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#app)': {
      margin: 0,
      padding: 0,
      height: '100vh'
    }
  }
});

initializeIcons();

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>, document.getElementById('app'));
