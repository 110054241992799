import React from 'react'
import {
  withRouter
} from "react-router-dom";
import { DetailsListLayoutMode, DetailsList, Stack, Text } from '@fluentui/react'
import { useQuery } from '@apollo/client';
import { TESTCASES } from './GraphQL/Queries';

const columns = [
  { key: 'name', name: 'Name', fieldName: 'name', minWidth: 250, isResizable: true },
  { key: 'author', name: 'Author', fieldName: 'author', minWidth: 250, isResizable: true },
  { key: 'description', name: 'Description', fieldName: 'description', minWidth: 250, isResizable: true, isMultiline: true },
  { key: 'tcVersionMain', name: 'Version Main', fieldName: 'tcVersionMain', minWidth: 100, isResizable: true },
  { key: 'tcCoverage', name: 'Coverage', fieldName: 'tcCoverage', minWidth: 100, isResizable: true, isMultiline: true },
  { key: 'focus', name: 'Focus', fieldName: 'focus', minWidth: 100, isResizable: true },
  { key: 'remarks', name: 'Remarks', fieldName: 'remarks', minWidth: 200, isResizable: true, isMultiline: true },
];

const _Testcases: React.FunctionComponent = (props: any) => {
  interface TestcaseData { testcases: [{
    dbId: string
    tcVersionMainMajor: string
    tcVersionMainMinor: string
    tcVersionLabMinor: string
    tcVersionLabCampaign: string
    tcApplicability: string
    tcCoverage: string
    focus: string
    name: string
    description: string
    remarks: string
    type: string
    siteData: string
    author: string
    state: string
  }]}
  const { loading, error, data } = useQuery<TestcaseData>(TESTCASES);

  if (loading) return <Text styles={{root: {padding: '20px'}}}>Loading...</Text>;
  if (error || !data) return <Text color="red" styles={{root: {margin: '20px'}}}>An error occurred. :(</Text>;

  const displayItems = data.testcases.map(x => ({...x, tcVersionMain: `${x.tcVersionMainMajor}.${x.tcVersionMainMinor}`}));

  return (
    <Stack>
      <Text variant="xxLarge" styles={{root: {padding: '20px'}}}>
        Testcases
      </Text>
      <DetailsList
          items={displayItems}
          columns={columns}
          setKey="set"
          styles={{root: {width: '100%'}}}
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="Row checkbox"
          onItemInvoked={(item: any) => {
            props.history.push(`/testcatalog/${item.dbId}`);
          }}
        />
    </Stack>
  )
}

export const Testcases = withRouter(_Testcases);
