import React from 'react'
import {
  useParams,
  withRouter
} from "react-router-dom";
import { DetailsListLayoutMode, DetailsList, Text } from '@fluentui/react'
import { useQuery, gql } from '@apollo/client';

const columns = [
  // { key: 'dbId', name: 'dbId', fieldName: 'dbId', minWidth: 250, isResizable: true },
  // { key: 'tcDbId', name: 'tcDbId', fieldName: 'tcDbId', minWidth: 250, isResizable: true },
  { key: 'paraOrderNumber', name: 'Order Number', fieldName: 'paraOrderNumber', minWidth: 100, maxWidth: 100, isResizable: true },
  { key: 'parameter', name: 'Parameter', fieldName: 'parameter', minWidth: 100, isResizable: true },
  { key: 'dataType', name: 'Data Type', fieldName: 'dataType', minWidth: 100, isResizable: true },
  { key: 'generalValue', name: 'General Value', fieldName: 'generalValue', minWidth: 100, isResizable: true },
  { key: 'remarks', name: 'Remarks', fieldName: 'remarks', minWidth: 100, isResizable: true },
];

const _Parameters: React.FunctionComponent = (props: any) => {
  let { testcaseId } = useParams<{testcaseId: string}>();

  const TESTSTEPS = gql`
  query test {
    parameters (order: { paraOrderNumber:ASC }, where:{ tcDbId: { eq: ${testcaseId} }}) {
      dbId
      tcDbId
      paraOrderNumber
      parameter
      dataType
      generalValue
      remarks
    }
  }
  `;
  interface ParameterData { parameters: [{
    dbId: string
    tcDbId: string
    paraOrderNumber: string
    parameter: string
    dataType: string
    generalValue: string
    remarks: string
  }]}
  const { loading, error, data } = useQuery<ParameterData>(TESTSTEPS);

  if (loading) return <p>Loading...</p>;
  if (error || !data) return <Text color="red" styles={{root: {margin: '20px'}}}>An error occurred. :(</Text>;

  return (
      <DetailsList
          items={data.parameters}
          columns={columns}
          setKey="set"
          styles={{root: {width: '100%'}}}
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="Row checkbox"
      />
  )
}

export const Parameters = withRouter(_Parameters);
