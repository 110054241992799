import { Text, Stack, FontWeights } from '@fluentui/react'
import React from 'react'

const boldStyle = {
  root: { fontWeight: FontWeights.semibold }
};


export const Dashboard: React.FunctionComponent = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      styles={{
        root: {
          margin: '0 auto',
          textAlign: 'center',
          color: '#605e5c'
        }
      }}
    >
      <Text variant="xxLarge" styles={boldStyle}>
        Welcome to the EULYNX Live Lab
      </Text>
    </Stack>
  )
}
