import * as React from 'react'
import { CheckboxVisibility, CommandBar, IColumn, SelectionMode, ShimmeredDetailsList, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { DynamicTestCaseData } from './DynamicTest'
import { DYNAMIC_TESTS } from '../GraphQL/Queries';

const columns: IColumn[] = [
    {
        key: 'name',
        fieldName: 'name',
        name: 'Name',
        minWidth: 160,
        maxWidth: 250,
        isResizable: true,
    },
    {
        key: 'id',
        fieldName: 'id',
        name: 'Test Case ID',
        minWidth: 160,
        maxWidth: 300,
        isResizable: true,
    },
    {
        key: 'authors',
        fieldName: 'authors',
        name: 'Authors',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
    },
    {
        key: 'description',
        fieldName: 'description',
        name: 'Description',
        minWidth: 600,
        maxWidth: 1200,
        isResizable: true,
        isMultiline: true,
    },
    {
        key: 'interlocking',
        fieldName: 'interlocking',
        name: 'Interlocking',
        minWidth: 400,
        isResizable: true,
    }
];

interface OverviewItem {
    dBId: string,
    name: string,
    id: string,
    authors: string,
    description: string,
    interlocking: string
}

function isOverviewItem(item: any): item is OverviewItem {
    if (typeof item !== 'object') return false;
    if (typeof item['dBId'] !== 'string') return false;
    if (typeof item['name'] !== 'string') return false;
    if (typeof item['authors'] !== 'string') return false;
    if (typeof item['id'] !== 'string') return false;
    if (typeof item['description'] !== 'string') return false;
    if (typeof item['interlocking'] !== 'string') return false;
    return true;
}

const _TestSpecializationOverview: React.FunctionComponent<RouteComponentProps> = (props) => {
    const { history } = props;
    const { loading, error, data } = useQuery<DynamicTestCaseData>(DYNAMIC_TESTS);

    const itemInvoked = (item?: any) => {
        if (!isOverviewItem(item)) {
            console.error("Invalid item clicked.", item);
            return;
        }
        history.push(`/test-specializations/${item.dBId}`);
    }

    const renderDynamicTests = () => {
        let testCases: OverviewItem[] | undefined;
        if (!!data) {
            testCases = data.dynamicTestCases.map((item) => ({
                dBId: item.dBId,
                name: item.name,
                id: item.id,
                authors: item.authors.join(", "),
                description: item.description,
                interlocking: item.interlocking.image,
            }));
        }

        return (
            <div>
                { loading && <Spinner styles={{root: {padding: '20px'}}} size={SpinnerSize.large} /> }
                { !loading && (error || !data) && <Text color="red" styles={{root: {margin: '20px'}}}>An error occurred. :(</Text>}
                { !error &&
                    <ShimmeredDetailsList
                        selectionMode={SelectionMode.single}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        columns={columns}
                        items={testCases || []}
                        enableShimmer={loading}
                        onItemInvoked={itemInvoked}
                        listProps={{style: { cursor: 'pointer' }}}
                    />
                }
            </div>
        );
    }

    return (
        <Stack
            styles={{root: {padding: '20px'}}}
            tokens={{
                childrenGap: 10,
            }}
        >
            <Text variant="xxLarge">
                Test Specializations
            </Text>
            <Text variant="large">
                Test Specializations can be triggered by hand. Depending on the test case, the peers can be selected beforehand.
            </Text>
            <CommandBar
                items={[
                {
                    key: 'new',
                    text: 'New',
                    iconProps: { iconName: 'Add' },
                    onClick: () => { props.history.push('/test-specializations/add') }
                }
                ]}
            />

            { renderDynamicTests() }
        </Stack>
    )

}

export const TestSpecializationOverview = withRouter(_TestSpecializationOverview);
