import * as React from "react"
import { getTheme } from "@fluentui/react"
import { LogMessages } from "../../DynamicTestRun";
import { Accordion } from "../../Accordion";
import { TextLog } from "./TextLog";

const theme = getTheme();

const logContainerStyle: React.CSSProperties = {
    marginTop: '0.5rem',
    backgroundColor: theme.palette.neutralDark,
    paddingLeft: '0rem',
    paddingRight: '0rem',
    // paddingBottom: '0.5rem',
    // paddingTop: '0.5rem',
    height: 700,
}

const logTextStyle: React.CSSProperties = {
    color: theme.palette.white,
    fontSize: 12,
}

export const LogDisplay: React.FunctionComponent<{ logs: LogMessages }> = ({logs}) => {
    return (
        <>
        {
            Object.keys(logs).sort().map((jobName) => (
                <Accordion key={jobName} title={`Logs of ${jobName}`} defaultCollapsed>
                    <div style={logContainerStyle}>
                        { <TextLog logLines={logs[jobName]} logStyle={logTextStyle} follow /> }
                    </div>
                </Accordion>
            ))
        }
        </>
    );
}
