import * as React from "react"
import { DocumentCard, DocumentCardDetails, DocumentCardImage, DocumentCardTitle, DocumentCardType, FontWeights,
    getTheme, IconButton, IIconProps, ImageFit, mergeStyleSets, Modal, PrimaryButton, Separator, Spinner, SpinnerSize, Stack, Text } from "@fluentui/react"
import { useId } from "@fluentui/react-hooks";

import { Device, DeviceData, DeviceList, DeviceType, imageForDeviceType } from "../Device";
import { getEnumKeyByEnumValue } from "../utils";
import { useLazyQuery } from "@apollo/client";
import { DEVICES_FILTER } from "../GraphQL/Queries";
import { SimulatedDeviceSpec } from "./DynamicTest";
import { useSelectedCardStyles } from "../Device/DeviceList";

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: "flex",
        flexFlow: "column nowrap",
        alignItems: "stretch",
    },
    header: [
        theme.fonts.xxLarge,
        {
            flex: "1 1 auto",
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: "flex",
            alignItems: "center",
            fontWeight: FontWeights.semibold,
            padding: "12px 12px 14px 24px",
        },
    ],
    body: {
        flex: "4 4 auto",
        padding: "0 24px 24px 24px",
        overflowY: "hidden",
        selectors: {
            p: { margin: "14px 0" },
            "p:first-child": { marginTop: 0 },
            "p:last-child": { marginBottom: 0 },
        },
    },
    selectionStack: {
        width: "100%",
        marginBottom: 12,
        display: "flex",
        justifyContent: "center",
    },
    errorText: {
        color: theme.semanticColors.errorText,
        margin: 20,
    },
    rightAligner: {
        display: "flex",
        justifyContent: "flex-end",
    },
    simulatorCenter: {
        display: "flex",
        justifyContent: "center",
    },
    peerContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: 200,
        minWidth: "70vw",
    }
});
const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};
const cancelIcon: IIconProps = { iconName: "Cancel" };

export interface PeerSelectionDialogProps {
    peerName: string,
    peerType: DeviceType,
    simulator?: SimulatedDeviceSpec,
    selection: SimulatedDeviceSpec | Device | null,
    isOpen: boolean,
    onClose: (selectedValue?: Device | SimulatedDeviceSpec) => void,
}

export const PeerSelectionModal: React.FunctionComponent<PeerSelectionDialogProps> = (props) => {
    const { peerName, peerType, isOpen, onClose, simulator } = props;
    const titleId = useId("title");

    // Use a lazy query to defer data fetching the data to the mooment of opening.
    const [loadMatchingPeers, { called, loading, data, error: deviceLoadError }] = useLazyQuery<DeviceData>(DEVICES_FILTER, {
        variables: {
            typeFilter: peerType,
        }
    })
    if (!called && isOpen) {
        loadMatchingPeers();
    }

    const simulatedDeviceImage = simulator ? imageForDeviceType(simulator.simulationType) : "";
    const selectedCardStyles = useSelectedCardStyles();

    const [selectedDevice, setSelectedDevice] = React.useState<[Device | SimulatedDeviceSpec, number] | null>(null);

    React.useEffect(() => {
        if (props.selection) {
            if ('simulationType' in props.selection) {
                setSelectedDevice([props.selection, NaN]);
            } else if (data && data.devices.indexOf(props.selection) !== -1) {
                setSelectedDevice([props.selection, data?.devices.indexOf(props.selection)]);
            }
        }
    }, [data, props.selection]);

    return (
        <Modal
            titleAriaId={titleId}
            isOpen={isOpen}
            isBlocking
        >
            <div className={contentStyles.header}>
                <span id={titleId}>Configure peer "{peerName}" of type {getEnumKeyByEnumValue(DeviceType, peerType)}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close peer selection"
                    onClick={() => onClose()}
                />
            </div>

            <div className={contentStyles.body}>
                <Text>The following peers can be chosen:</Text>
                <div className={contentStyles.peerContent}>
                    { loading &&
                        <Spinner size={SpinnerSize.large} />
                    }
                    { !loading && deviceLoadError &&
                        <Text className={contentStyles.errorText}>An error occurred. :(</Text>
                    }
                    { !loading && data &&
                        <Stack tokens={{childrenGap: 0}} className={contentStyles.selectionStack}>
                            <DeviceList
                                devices={data.devices}
                                onDeviceClick={(d, index) => setSelectedDevice([d, index])}
                                enablePersistentSelection
                                selectionIndex={selectedDevice?.[1]} />
                            { !!simulator &&
                                <>
                                    <Separator />
                                    <div className={contentStyles.simulatorCenter}>
                                        <DocumentCard
                                            type={DocumentCardType.compact}
                                            aria-label="Use the simulator"
                                            onClick={() => setSelectedDevice([simulator, NaN])}
                                            className={selectedDevice && isNaN(selectedDevice[1]) ? selectedCardStyles.selectedCard : undefined}
                                        >
                                            <DocumentCardImage
                                                imageSrc={simulatedDeviceImage}
                                                imageFit={ImageFit.cover}
                                                width={192}
                                                styles={{cornerIcon: { display: 'none' }}} />
                                            <DocumentCardDetails>
                                                <DocumentCardTitle
                                                    title="Simulator"
                                                    shouldTruncate />
                                                <DocumentCardTitle
                                                    title={simulator.image}
                                                    showAsSecondaryTitle
                                                />
                                            </DocumentCardDetails>
                                        </DocumentCard>
                                    </div>
                                </>
                            }
                        </Stack>
                    }
                </div>
                <div className={contentStyles.rightAligner}>
                    <PrimaryButton
                        text="Select"
                        ariaDescription="Choose the selected device as peer"
                        allowDisabledFocus
                        disabled={!selectedDevice}
                        onClick={() => selectedDevice && onClose(selectedDevice[0])}
                        />
                </div>
            </div>
        </Modal>
    );
}
