import React from 'react'
import {
  useParams,
  withRouter
} from "react-router-dom";
import { DetailsListLayoutMode, DetailsList, Text } from '@fluentui/react'
import { useQuery, gql } from '@apollo/client';

const columns = [
  { key: 'preOrderNumber', name: 'Pre Order Number', fieldName: 'preOrderNumber', minWidth: 100, maxWidth: 100, isResizable: true },
  { key: 'domain', name: 'Domain', fieldName: 'domain', minWidth: 100, isResizable: true },
  { key: 'state', name: 'State', fieldName: 'state', minWidth: 100, isResizable: true },
  { key: 'instance', name: 'Instance', fieldName: 'name', minWidth: 100, isResizable: true },
];

const _Preconditions: React.FunctionComponent = (props: any) => {
  let { testcaseId } = useParams<{testcaseId: string}>();

  const TESTSTEPS = gql`
  query test {
    preconditions (where:{ tcDbId: { eq: ${testcaseId} }}) {
      dbId
      tcDbId
      preOrderNumber
      instance
      domain
      state
    }
  }
  `;
  interface PreconditionData { preconditions: [{
    dbId: string
    tcDbId: string
    preOrderNumber: string
    instance: string
    domain: string
    state: string
  }]}
  const { loading, error, data } = useQuery<PreconditionData>(TESTSTEPS);

  if (loading) return <p>Loading...</p>;
  if (error || !data) return <Text color="red" styles={{root: {margin: '20px'}}}>An error occurred. :(</Text>;

  return (
      <DetailsList
          items={data.preconditions}
          columns={columns}
          setKey="set"
          styles={{root: {width: '100%'}}}
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="Row checkbox"
      />
  )
}

export const Preconditions = withRouter(_Preconditions);
