import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  RouteComponentProps,
  Redirect,
} from "react-router-dom";
import { Stack, Text, Nav, INavStyles, INavLinkGroup, INavProps, Pivot, PivotItem } from '@fluentui/react';

import { Dashboard } from './Dashboard';
import { Testcases } from './Testcases';
import { Locations } from './Locations';
import { AddLocation } from './AddLocation';
import { DeviceOverview } from './Device';
import { AddDevice } from './AddDevice';
import { RastaStacks } from './RastaStacks';
import { Teststeps } from './Teststeps';
import { Preconditions } from './Preconditions';
import { Postconditions } from './Postconditions';
import { Parameters } from './Parameters';
import {
  TestSpecializationCreate,
  TestSpecializationDetails,
  TestSpecializationOverview,
  TestSpecializationRunDetails,
  TestSpecializationRunResults,
} from './TestSpecializations';
import { PlanningProjects } from './PlanningProjects/List';
import { InteractiveDemo } from './InteractiveDemo';
import { NoMatch } from './NoMatch';
import { Fahrzeugparade } from './Fahrzeugparade';
import { PlanningProjectCreate } from './PlanningProjects/Create';
import { PlanningProjectView } from './PlanningProjects/Details';

const NAV_WIDTH = window.location.pathname.includes('demo') ? 0 : 300;
const navStyles: Partial<INavStyles> = { root: { width: NAV_WIDTH } };

const navLinkGroups: INavLinkGroup[] = [
  {
    name: 'EULYNX Live Lab',
    links: [
      {
        key: 'dashboard',
        name: 'Dashboard',
        url: '/',
      },
      {
        key: 'locations',
        name: 'Locations',
        url: '/locations',
      },
      {
        key: 'inventory',
        name: 'Inventory',
        url: '/devices',
      },
      {
        key: 'rasta',
        name: 'RaSTA Cross-Testing',
        url: '/rasta',
      },
    ],
  },
  {
    name: 'Planning and Specification',
    links: [
      {
        key: 'projects',
        name: 'Projects',
        url: '/projects',
      },
      {
        key: 'testcases',
        name: 'Test Cases',
        url: '/testcatalog',
      },
    ],
  },
  {
    name: 'Testing',
    links: [
      {
        key: 'testSpecializations',
        name: 'Test Specializations',
        url: '/test-specializations'
      },
      {
        key: 'testresults',
        name: 'Test Results',
        url: '/test-results'
      }
    ],
  },
  // {
  //   name: 'Operations',
  //   links: [
  //     {
  //       key: 'monitoring',
  //       name: 'Monitoring',
  //       url: '/monitoring',
  //     },
  //   ],
  // },
];

const _FabricNav: React.FunctionComponent<INavProps & RouteComponentProps> = props => {
    if (props.location.pathname.toLowerCase().indexOf('demo') === -1) {
      return (<Nav
        {...props}
        onLinkClick={(event, element) => {
          if (element && element.url.startsWith('//')) {
            return;
          }
          if (event) {
            event.preventDefault();
          }
          if (element) {
            props.history.push(element.url);
          }
        }}
      />);
    }
    return null;
}

const FabricNav = withRouter(_FabricNav);

export const App: React.FunctionComponent = () => {
  return (
    <Router>
      <Stack horizontal verticalFill>
        <FabricNav
          styles={navStyles}
          groups={navLinkGroups}
         />
        <Stack
          verticalFill
          data-is-scrollable="true"
          styles={{
            root: {
              margin: '0 auto',
              width: `calc(100vw - ${NAV_WIDTH}px)`
            },
          }}
        >
          <Switch>
            <Route exact path="/">
              <Dashboard />
            </Route>
            <Route exact path="/demo">
              <InteractiveDemo />
            </Route>
            <Route exact path="/demoday">
              <Fahrzeugparade />
            </Route>
            <Route exact path="/locations">
              <Locations/>
            </Route>
            <Route path="/locations/add">
              <AddLocation/>
            </Route>
            <Route exact path="/devices">
              <DeviceOverview />
            </Route>
            <Route path="/devices/add">
              <AddDevice/>
            </Route>
            <Route path="/rasta">
              <RastaStacks />
            </Route>
            <Route exact path="/projects">
              <PlanningProjects/>
            </Route>
            <Route exact path="/projects/add">
              <PlanningProjectCreate />
            </Route>
            <Route path="/projects/:projectId" render={props => (<PlanningProjectView {...props} />)} />
            <Route exact path="/testcatalog">
              <Testcases/>
            </Route>
            <Route path="/testcatalog/:testcaseId">
              <Pivot aria-label="Basic Pivot Example">
                <PivotItem headerText="Parameters">
                  <Parameters />
                </PivotItem>
                <PivotItem headerText="Preconditions">
                  <Preconditions/>
                </PivotItem>
                <PivotItem headerText="Teststeps">
                  <Teststeps/>
                </PivotItem>
                <PivotItem headerText="Postconditions">
                  <Postconditions/>
                </PivotItem>
              </Pivot>
            </Route>
            <Route path="/testruns">
              <Text>testruns</Text>
            </Route>
            <Route path="/test-specializations">
              <Switch>
                <Route path="/test-specializations/add">
                  <TestSpecializationCreate />
                </Route>
                <Route path="/test-specializations/:testCaseDbId">
                  <Switch>
                    <Route path="/test-specializations/:testCaseDbId/:testRun">
                      <TestSpecializationRunDetails />
                    </Route>
                    <Route path="/test-specializations/:testCaseDbId">
                      <TestSpecializationDetails />
                    </Route>
                  </Switch>
                </Route>
                <Route path="/test-specializations">
                  <TestSpecializationOverview />
                </Route>
              </Switch>
            </Route>
            <Route path="/test-results">
              <TestSpecializationRunResults />
            </Route>
            <Route path="/monitoring">
              <Text>monitoring</Text>
            </Route>
            <Route path="/404" component={NoMatch} />
            <Redirect to="/404" />
          </Switch>
         </Stack>
       </Stack>
    </Router>
  );
};
