import React from 'react'
import { Label, PrimaryButton, Stack, Text, TextField } from '@fluentui/react'
import { gql, useMutation } from '@apollo/client';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { DEVICES } from './GraphQL/Queries';

const ADD_DEVICE = gql`
  mutation AddDevice($name: String!, $type: DeviceType, $node: String!, $image: String!,
    $rasta_crcCode: String!,
    $rasta_host: String!,
    $rasta_md4: String!,
    $rasta_md4Start_00: String!,
    $rasta_md4Start_01: String!,
    $rasta_md4Start_02: String!,
    $rasta_md4Start_03: String!,
    $rasta_mwa: Int!,
    $rasta_nDeferQueueSize: Int!,
    $rasta_nDiagnosis: Int!,
    $rasta_nDiagWindow: Int!,
    $rasta_nMaxPacket: Int!,
    $rasta_nSendmax: Int!,
    $rasta_rastaLocalId: String!,
    $rasta_rastaNetwork: String!,
    $rasta_rastaRemoteId: String!,
    $rasta_rastaVersion: String!,
    $rasta_senderId: String!,
    $rasta_receiverId: String!,
    $rasta_th: Int!,
    $rasta_tmax: Int!,
    $rasta_localPortChannel0: Int!,
    $rasta_remotePortChannel0: Int!,
    $rasta_localPortChannel1: Int!,
    $rasta_remotePortChannel1: Int!,
    $rasta_transportCnt: Int!,
    $rasta_tSeq: Int!) {
    addDevice(device: { name: $name, connectionType: rasta_tls, type: $type, node: $node, image: $image, rastaTlsConfig: {
      crcCode: $rasta_crcCode,
      host: $rasta_host,
      md4: $rasta_md4,
      md4Start_00: $rasta_md4Start_00,
      md4Start_01: $rasta_md4Start_01,
      md4Start_02: $rasta_md4Start_02,
      md4Start_03: $rasta_md4Start_03,
      mWA: $rasta_mwa,
      nDeferQueueSize: $rasta_nDeferQueueSize,
      nDiagnosis: $rasta_nDiagnosis,
      nDiagWindow: $rasta_nDiagWindow,
      nMaxPacket: $rasta_nMaxPacket,
      nSendmax: $rasta_nSendmax,
      rastaLocalID: $rasta_rastaLocalId,
      rastaNetwork: $rasta_rastaNetwork,
      rastaRemoteID: $rasta_rastaRemoteId,
      rastaVersion: $rasta_rastaVersion,
      senderID: $rasta_senderId,
      receiverID: $rasta_receiverId,
      th: $rasta_th,
      tmax: $rasta_tmax,
      localPortChannel0: $rasta_localPortChannel0,
      remotePortChannel0: $rasta_remotePortChannel0,
      localPortChannel1: $rasta_localPortChannel1,
      remotePortChannel1: $rasta_remotePortChannel1,
      transportCnt: $rasta_transportCnt,
      tSeq: $rasta_tSeq,
    } }) {
      name
    }
  }
`;

const AddDeviceInternal: React.FunctionComponent<RouteComponentProps> = (props) => {
  const defaults = {
    'name': '',
    'type': '',
    'node': '',
    'image': '',
    'rasta.crcCode': 'CRC32',
    'rasta.host': '',
    'rasta.md4': 'HALF_SAFETYCODE',
    'rasta.md4Start_00': '0x67452301',
    'rasta.md4Start_01': '0xEFCDAB89',
    'rasta.md4Start_02': '0x98BADCFE',
    'rasta.md4Start_03': '0x10325476',
    'rasta.mwa': 10,
    'rasta.nDeferQueueSize': 10,
    'rasta.nDiagnosis': 1000,
    'rasta.nDiagWindow': 1000,
    'rasta.nMaxPacket': 5,
    'rasta.nSendmax': 20,
    'rasta.rastaLocalId': '',
    'rasta.rastaNetwork': '',
    'rasta.rastaRemoteId': '',
    'rasta.rastaVersion': '0303',
    'rasta.senderId': '',
    'rasta.receiverId': '',
    'rasta.th': 300,
    'rasta.tmax': 2000,
    'rasta.localPortChannel0': null,
    'rasta.remotePortChannel0': null,
    'rasta.localPortChannel1': null,
    'rasta.remotePortChannel1': null,
    'rasta.transportCnt': 1,
    'rasta.tSeq': 200,
  };

  const formValues: { [field: string] : [any, React.Dispatch<React.SetStateAction<any>>]; } = {
    'name': React.useState(defaults['name']),
    'type': React.useState(defaults['type']),
    'node': React.useState(defaults['node']),
    'image': React.useState(defaults['image']),
    'rasta.crcCode': React.useState(defaults['rasta.crcCode']),
    'rasta.host': React.useState(defaults['rasta.host']),
    'rasta.md4': React.useState(defaults['rasta.md4']),
    'rasta.md4Start_00': React.useState(defaults['rasta.md4Start_00']),
    'rasta.md4Start_01': React.useState(defaults['rasta.md4Start_01']),
    'rasta.md4Start_02': React.useState(defaults['rasta.md4Start_02']),
    'rasta.md4Start_03': React.useState(defaults['rasta.md4Start_03']),
    'rasta.mwa': React.useState(defaults['rasta.mwa']),
    'rasta.nDeferQueueSize': React.useState(defaults['rasta.nDeferQueueSize']),
    'rasta.nDiagnosis': React.useState(defaults['rasta.nDiagnosis']),
    'rasta.nDiagWindow': React.useState(defaults['rasta.nDiagWindow']),
    'rasta.nMaxPacket': React.useState(defaults['rasta.nMaxPacket']),
    'rasta.nSendmax': React.useState(defaults['rasta.nSendmax']),
    'rasta.rastaLocalId': React.useState(defaults['rasta.rastaLocalId']),
    'rasta.rastaNetwork': React.useState(defaults['rasta.rastaNetwork']),
    'rasta.rastaRemoteId': React.useState(defaults['rasta.rastaRemoteId']),
    'rasta.rastaVersion': React.useState(defaults['rasta.rastaVersion']),
    'rasta.senderId': React.useState(defaults['rasta.senderId']),
    'rasta.receiverId': React.useState(defaults['rasta.receiverId']),
    'rasta.th': React.useState(defaults['rasta.th']),
    'rasta.tmax': React.useState(defaults['rasta.tmax']),
    'rasta.localPortChannel0': React.useState(defaults['rasta.localPortChannel0']),
    'rasta.remotePortChannel0': React.useState(defaults['rasta.remotePortChannel0']),
    'rasta.localPortChannel1': React.useState(defaults['rasta.localPortChannel1']),
    'rasta.remotePortChannel1': React.useState(defaults['rasta.remotePortChannel1']),
    'rasta.transportCnt': React.useState(defaults['rasta.transportCnt']),
    'rasta.tSeq': React.useState(defaults['rasta.tSeq']),
  };

  const useChangeTextFieldValue = (field: string) => React.useCallback(
    (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (field in formValues) {
        const [, setTextFieldValue] = formValues[field];
        setTextFieldValue(newValue || '');
      }
    },
    [field],
  );

  const useChangeTextFieldValueNumeric = (field: string) => React.useCallback(
    (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
      if (field in formValues) {
        const [, setTextFieldValue] = formValues[field];
        setTextFieldValue(parseInt(newValue || ''));
      }
    },
    [field],
  );

  const [addDevice,] = useMutation(ADD_DEVICE, { refetchQueries: [ { query: DEVICES } ] });

  return (
    <Stack>
      <Text variant="xxLarge" styles={{root: {padding: '20px'}}}>
        Add Device
      </Text>
      <div>
        <Stack styles={{root: { width: 300, padding: '20px' }}}>
          <Label htmlFor={'name'}>Device Name</Label>
          <TextField id={'name'} required value={formValues['name'][0]} onChange={useChangeTextFieldValue('name')} />

          <Label htmlFor={'type'}>Device Type</Label>
          <TextField id={'type'} required value={formValues['type'][0]} onChange={useChangeTextFieldValue('type')} />

          <Label htmlFor={'node'}>Lab Node</Label>
          <TextField id={'node'} required value={formValues['node'][0]} onChange={useChangeTextFieldValue('node')} />

          <Label htmlFor={'image'}>Image URL</Label>
          <TextField id={'image'} required value={formValues['image'][0]} onChange={useChangeTextFieldValue('image')} />

          <Label htmlFor={'rasta.crcCode'}>RaSTA CRC Code</Label>
          <TextField id={'rasta.crcCode'} required value={formValues['rasta.crcCode'][0]} onChange={useChangeTextFieldValue('rasta.crcCode')} />

          <Label htmlFor={'rasta.host'}>RaSTA Host</Label>
          <TextField id={'rasta.host'} required value={formValues['rasta.host'][0]} onChange={useChangeTextFieldValue('rasta.host')} />

          <Label htmlFor={'rasta.md4'}>RaSTA MD4</Label>
          <TextField id={'rasta.md4'} required value={formValues['rasta.md4'][0]} onChange={useChangeTextFieldValue('rasta.md4')} />

          <Label htmlFor={'rasta.md4Start_00'}>RaSTA MD4 Start 00</Label>
          <TextField id={'rasta.md4Start_00'} required value={formValues['rasta.md4Start_00'][0]} onChange={useChangeTextFieldValue('rasta.md4Start_00')} />

          <Label htmlFor={'rasta.md4Start_01'}>RaSTA MD4 Start 01</Label>
          <TextField id={'rasta.md4Start_01'} required value={formValues['rasta.md4Start_01'][0]} onChange={useChangeTextFieldValue('rasta.md4Start_01')} />

          <Label htmlFor={'rasta.md4Start_02'}>RaSTA MD4 Start 02</Label>
          <TextField id={'rasta.md4Start_02'} required value={formValues['rasta.md4Start_02'][0]} onChange={useChangeTextFieldValue('rasta.md4Start_02')} />

          <Label htmlFor={'rasta.md4Start_03'}>RaSTA MD4 Start 03</Label>
          <TextField id={'rasta.md4Start_03'} required value={formValues['rasta.md4Start_03'][0]} onChange={useChangeTextFieldValue('rasta.md4Start_03')} />

          <Label htmlFor={'rasta.mwa'}>RaSTA MWA</Label>
          <TextField id={'rasta.mwa'} required value={formValues['rasta.mwa'][0]} type={'number'} onChange={useChangeTextFieldValueNumeric('rasta.mwa')} />

          <Label htmlFor={'rasta.nDeferQueueSize'}>RaSTA N Defer Queue Size</Label>
          <TextField id={'rasta.nDeferQueueSize'} required value={formValues['rasta.nDeferQueueSize'][0]} onChange={useChangeTextFieldValueNumeric('rasta.nDeferQueueSize')} />

          <Label htmlFor={'rasta.nDiagnosis'}>RaSTA N Diagnosis</Label>
          <TextField id={'rasta.nDiagnosis'} required value={formValues['rasta.nDiagnosis'][0]} onChange={useChangeTextFieldValueNumeric('rasta.nDiagnosis')} />

          <Label htmlFor={'rasta.nDiagWindow'}>RaSTA N Diag Window</Label>
          <TextField id={'rasta.nDiagWindow'} required value={formValues['rasta.nDiagWindow'][0]} onChange={useChangeTextFieldValueNumeric('rasta.nDiagWindow')} />

          <Label htmlFor={'rasta.nMaxPacket'}>RaSTA N Max Packet</Label>
          <TextField id={'rasta.nMaxPacket'} required value={formValues['rasta.nMaxPacket'][0]} onChange={useChangeTextFieldValueNumeric('rasta.nMaxPacket')} />

          <Label htmlFor={'rasta.nSendmax'}>RaSTA N Sendmax</Label>
          <TextField id={'rasta.nSendmax'} required value={formValues['rasta.nSendmax'][0]} onChange={useChangeTextFieldValueNumeric('rasta.nSendmax')} />

          <Label htmlFor={'rasta.rastaNetwork'}>RaSTA Network</Label>
          <TextField id={'rasta.rastaNetwork'} required value={formValues['rasta.rastaNetwork'][0]} onChange={useChangeTextFieldValue('rasta.rastaNetwork')} />

          <Label htmlFor={'rasta.rastaLocalId'}>RaSTA Local Id</Label>
          <TextField id={'rasta.rastaLocalId'} required value={formValues['rasta.rastaLocalId'][0]} onChange={useChangeTextFieldValue('rasta.rastaLocalId')} />

          <Label htmlFor={'rasta.rastaRemoteId'}>RaSTA Remote Id</Label>
          <TextField id={'rasta.rastaRemoteId'} required value={formValues['rasta.rastaRemoteId'][0]} onChange={useChangeTextFieldValue('rasta.rastaRemoteId')} />

          <Label htmlFor={'rasta.rastaVersion'}>RaSTA Version</Label>
          <TextField id={'rasta.rastaVersion'} required value={formValues['rasta.rastaVersion'][0]} onChange={useChangeTextFieldValue('rasta.rastaVersion')} />

          <Label htmlFor={'rasta.receiverId'}>RaSTA Receiver Id</Label>
          <TextField id={'rasta.receiverId'} required value={formValues['rasta.receiverId'][0]} onChange={useChangeTextFieldValue('rasta.receiverId')} />

          <Label htmlFor={'rasta.senderId'}>RaSTA Sender Id</Label>
          <TextField id={'rasta.senderId'} required value={formValues['rasta.senderId'][0]} onChange={useChangeTextFieldValue('rasta.senderId')} />

          <Label htmlFor={'rasta.th'}>RaSTA Th</Label>
          <TextField id={'rasta.th'} required value={formValues['rasta.th'][0]} onChange={useChangeTextFieldValueNumeric('rasta.th')} />

          <Label htmlFor={'rasta.tmax'}>RaSTA Tmax</Label>
          <TextField id={'rasta.tmax'} required value={formValues['rasta.tmax'][0]} onChange={useChangeTextFieldValueNumeric('rasta.tmax')} />

          <Label htmlFor={'rasta.localPortChannel0'}>RaSTA Local Port Channel 0</Label>
          <TextField id={'rasta.localPortChannel0'} required value={formValues['rasta.localPortChannel0'][0]} onChange={useChangeTextFieldValueNumeric('rasta.localPortChannel0')} />

          <Label htmlFor={'rasta.remotePortChannel0'}>RaSTA Remote Port Channel 0</Label>
          <TextField id={'rasta.remotePortChannel0'} required value={formValues['rasta.remotePortChannel0'][0]} onChange={useChangeTextFieldValueNumeric('rasta.remotePortChannel0')} />

          <Label htmlFor={'rasta.localPortChannel1'}>RaSTA Local Port Channel 1</Label>
          <TextField id={'rasta.localPortChannel1'} required value={formValues['rasta.localPortChannel1'][0]} onChange={useChangeTextFieldValueNumeric('rasta.localPortChannel1')} />

          <Label htmlFor={'rasta.remotePortChannel1'}>RaSTA Remote Port Channel 1</Label>
          <TextField id={'rasta.remotePortChannel1'} required value={formValues['rasta.remotePortChannel1'][0]} onChange={useChangeTextFieldValueNumeric('rasta.remotePortChannel1')} />

          <Label htmlFor={'rasta.transportCnt'}>RaSTA Transport Count</Label>
          <TextField id={'rasta.transportCnt'} required value={formValues['rasta.transportCnt'][0]} onChange={useChangeTextFieldValueNumeric('rasta.transportCnt')} />

          <Label htmlFor={'rasta.tSeq'}>RaSTA T Seq</Label>
          <TextField id={'rasta.tSeq'} required value={formValues['rasta.tSeq'][0]} onChange={useChangeTextFieldValueNumeric('rasta.tSeq')} />
        </Stack>
        <PrimaryButton text="Save" styles={{root: {margin: '20px'}}} onClick={ async () => {
          await addDevice({ variables: {
            'name': formValues['name'][0],
            'type': formValues['type'][0],
            'node': formValues['node'][0],
            'image': formValues['image'][0],
            'rasta_crcCode': formValues['rasta.crcCode'][0],
            'rasta_host': formValues['rasta.host'][0],
            'rasta_md4': formValues['rasta.md4'][0],
            'rasta_md4Start_00': formValues['rasta.md4Start_00'][0],
            'rasta_md4Start_01': formValues['rasta.md4Start_01'][0],
            'rasta_md4Start_02': formValues['rasta.md4Start_02'][0],
            'rasta_md4Start_03': formValues['rasta.md4Start_03'][0],
            'rasta_mwa': formValues['rasta.mwa'][0],
            'rasta_nDeferQueueSize': formValues['rasta.nDeferQueueSize'][0],
            'rasta_nDiagnosis': formValues['rasta.nDiagnosis'][0],
            'rasta_nDiagWindow': formValues['rasta.nDiagWindow'][0],
            'rasta_nMaxPacket': formValues['rasta.nMaxPacket'][0],
            'rasta_nSendmax': formValues['rasta.nSendmax'][0],
            'rasta_rastaLocalId': formValues['rasta.rastaLocalId'][0],
            'rasta_rastaNetwork': formValues['rasta.rastaNetwork'][0],
            'rasta_rastaRemoteId': formValues['rasta.rastaRemoteId'][0],
            'rasta_rastaVersion': formValues['rasta.rastaVersion'][0],
            'rasta_senderId': formValues['rasta.senderId'][0],
            'rasta_receiverId': formValues['rasta.receiverId'][0],
            'rasta_th': formValues['rasta.th'][0],
            'rasta_tmax': formValues['rasta.tmax'][0],
            'rasta_localPortChannel0': formValues['rasta.localPortChannel0'][0],
            'rasta_remotePortChannel0': formValues['rasta.remotePortChannel0'][0],
            'rasta_localPortChannel1': formValues['rasta.localPortChannel1'][0],
            'rasta_remotePortChannel1': formValues['rasta.remotePortChannel1'][0],
            'rasta_transportCnt': formValues['rasta.transportCnt'][0],
            'rasta_tSeq': formValues['rasta.tSeq'][0],
          } });

          for (var [field, defaultValue] of Object.entries(defaults)) {
            const setter = formValues[field][1];
            setter(defaultValue);
          }

          props.history.replace('/devices');
        }} />
      </div>
    </Stack>
  )
}

export const AddDevice = withRouter(AddDeviceInternal);
