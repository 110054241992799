import React, { useState } from 'react'
import { Stack, Text, CommandBar, Spinner, SpinnerSize } from '@fluentui/react'

import { useQuery } from '@apollo/client';
import { withRouter } from 'react-router-dom';

import { DEVICES } from '../GraphQL/Queries';
import { DeviceInfoPanel } from './DeviceInfoPanel';
import { Device, DeviceData } from '.';
import { DeviceList } from './DeviceList';

const _DeviceOverview: React.FunctionComponent = (props: any) => {
  const { loading, error, data } = useQuery<DeviceData>(DEVICES);

  const [selectedDevice, setSelectedDevice] = useState<Device>();

  const renderDevices = () => {
    if (loading) return <Spinner styles={{root: {padding: '20px'}}} size={SpinnerSize.large} />;
    if (error || !data) return <Text color="red" styles={{root: {margin: '20px'}}}>An error occurred. :(</Text>;

    return (
      <div>
        <DeviceInfoPanel
          open={!!selectedDevice}
          device={selectedDevice}
          onClose={() => setSelectedDevice(undefined)} />
        <DeviceList devices={data.devices} onDeviceClick={(device) => setSelectedDevice(device)} />
      </div>
    );
  }

  return (
    <Stack>
      <Text variant="xxLarge" styles={{root: {padding: '20px'}}}>
        Devices
      </Text>
      <CommandBar
        items={[
          {
            key: 'new',
            text: 'New',
            iconProps: { iconName: 'Add' },
            onClick: () => { props.history.push('/devices/add') }
          }
        ]}
      />
      { renderDevices() }
    </Stack>
  )
}

export const DeviceOverview = withRouter(_DeviceOverview);
