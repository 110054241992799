import * as React from 'react';
import { Panel, DetailsList, SelectionMode, IColumn, IGroup, ConstrainMode } from '@fluentui/react';

import { Device, DeviceType } from '.'
import { getEnumKeyByEnumValue } from '../utils';

// const dialogContentProps = {
//     type: DialogType.normal,
//     title: 'Are you sure you want to close the panel?',
// };
// const dialogModalProps = {
//     isBlocking: true,
//     styles: { main: { maxWidth: 450 } },
// };
const detailColumns: IColumn[] = [
    {
        name: 'Option',
        key: 'option',
        fieldName: 'option',
        minWidth: 50,
        isResizable: true,
    },
    {
        name: 'Value',
        key: 'value',
        fieldName: 'value',
        minWidth: 120,
        isResizable: true,
    }
]

interface DeviceInfoProps {
    open: boolean,
    device: Device | undefined,
    onClose: () => void,
}

export const DeviceInfoPanel: React.FunctionComponent<DeviceInfoProps> = (props) => {
    const { open, onClose, device } = props;
    // const [isDialogVisible, setIsDialogVisible] = React.useState(false);

    const onDismiss = React.useCallback((ev?: React.SyntheticEvent | KeyboardEvent) => {
        if (ev) {
            onClose();
            // // Instead of closing the panel immediately, cancel that action and show a dialog
            // ev.preventDefault();
            // setIsDialogVisible(true);
        }
    }, [onClose]);

    // const hideDialog = React.useCallback(() => setIsDialogVisible(false), []);
    // const hideDialogAndPanel = React.useCallback(() => {
    //     setIsDialogVisible(false);
    //     onClose();
    // }, []);

    if (!!device) {
        const items: {option: string, value: string}[] = [];
        // General config.
        items.push({
            option: 'Name',
            value: device.name,
        });
        items.push({
            option: 'Lab Node',
            value: device.node,
        });
        let deviceType = getEnumKeyByEnumValue(DeviceType, device.type);
        if (deviceType === null) {
            deviceType = "Unknown";
        }
        items.push({
            option: 'Device Type',
            value: deviceType,
        });
        // RaSTA config.
        for (var [option, value] of Object.entries(device.rastaConfig).sort((a, b) => a[0].localeCompare(b[0]))) {
            items.push({
                option,
                value,
            });
        }

        const groups: IGroup[] = [
            {
                key: 'groupGeneral',
                name: 'General',
                startIndex: 0,
                count: 3,
                level: 0
            },
            {
                key: 'groupRasta',
                name: 'RaSTA',
                startIndex: 3,
                count: items.length - 3,
                level: 0,
            },
        ];

        return (
        <div>
            <Panel
                headerText={device.name}
                isOpen={open}
                isLightDismiss
                onDismiss={onDismiss}
                closeButtonAriaLabel="Close"
                data-is-scrollable="true"
            >
                <DetailsList
                    styles={{
                        root: {
                            width: '100%',
                            overflow: 'hidden',
                        }
                    }}
                    items={items}
                    columns={detailColumns}
                    groups={groups}
                    constrainMode={ConstrainMode.horizontalConstrained}
                    disableSelectionZone={true}
                    selectionMode={SelectionMode.none}
                    data-is-scrollable="false"
                    />
            </Panel>
            {/* <Dialog
                hidden={!isDialogVisible}
                onDismiss={hideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={dialogModalProps}
            >
                <DialogFooter>
                <PrimaryButton onClick={hideDialogAndPanel} text="Yes" />
                <DefaultButton onClick={hideDialog} text="No" />
                </DialogFooter>
            </Dialog> */}
        </div>
        );
    }
    return (
        <div></div>
    )
};
