import React from 'react'
import {
  useParams,
  withRouter
} from "react-router-dom";
import { DetailsListLayoutMode, DetailsList, Text } from '@fluentui/react'
import { useQuery } from '@apollo/client';
import { TESTSTEPS } from './GraphQL/Queries';

const columns = [
  // { key: 'dbId', name: 'dbId', fieldName: 'dbId', minWidth: 250, isResizable: true },
  // { key: 'tcDbId', name: 'tcDbId', fieldName: 'tcDbId', minWidth: 250, isResizable: true },
  { key: 'tcStepNumber', name: 'Step Number', fieldName: 'tcStepNumber', minWidth: 100, isResizable: true },
  { key: 'name', name: 'Name', fieldName: 'name', minWidth: 250, isResizable: true },
  { key: 'instance', name: 'Instance', fieldName: 'instance', minWidth: 100, isResizable: true },
  { key: 'if', name: 'Interface', fieldName: 'if', minWidth: 100, isResizable: true },
  { key: 'dir', name: 'Direction', fieldName: 'dir', minWidth: 50, isResizable: true },
  { key: 'description', name: 'Description', fieldName: 'description', minWidth: 250, isResizable: true, isMultiline: true },
  { key: 'remarks', name: 'Remarks', fieldName: 'remarks', minWidth: 250, isResizable: true, isMultiline: true },
  { key: 'formalisation', name: 'Formalisation', fieldName: 'formalisation', minWidth: 250, isResizable: true, isMultiline: true },
  { key: 'timing', name: 'Timing', fieldName: 'timing', minWidth: 250, isResizable: true },
  { key: 'reactionBlock', name: 'Reaction Block', fieldName: 'reactionBlock', minWidth: 250, isResizable: true },
  { key: 'trigger', name: 'Trigger', fieldName: 'trigger', minWidth: 250, isResizable: true },
  { key: 'tsApplicability', name: 'Applicability', fieldName: 'tsApplicability', minWidth: 250, isResizable: true },
  { key: 'tsCoverage', name: 'Coverage', fieldName: 'tsCoverage', minWidth: 250, isResizable: true, isMultiline: true },
];

const _Teststeps: React.FunctionComponent = (props: any) => {
  let { testcaseId } = useParams<{testcaseId: string}>();
  interface TeststepData { teststeps: [{
    dbId: string
    tcDbId: string
    tcStepNumber: string
    tsApplicability: string
    tsCoverage: string
    instance: string
    if: string
    dir: string
    name: string
    description: string
    remarks: string
    formalisation: string
    timing: string
    reactionBlock: string
    trigger: string
  }]}
  const { loading, error, data } = useQuery<TeststepData>(TESTSTEPS, { variables: { testcaseId } });

  if (loading) return <p>Loading...</p>;
  if (error || !data) return <Text color="red" styles={{root: {margin: '20px'}}}>An error occurred. :(</Text>;

  return (
      <DetailsList
          items={data.teststeps}
          columns={columns}
          setKey="set"
          styles={{root: {width: '100%'}}}
          layoutMode={DetailsListLayoutMode.justified}
          selectionPreservedOnEmptyClick={true}
          ariaLabelForSelectionColumn="Toggle selection"
          ariaLabelForSelectAllCheckbox="Toggle selection for all items"
          checkButtonAriaLabel="Row checkbox"
      />
  )
}

export const Teststeps = withRouter(_Teststeps);
