import React, { FunctionComponent, CSSProperties } from 'react'
import { IStyleSet, ITextStyles, Text } from '@fluentui/react'

const containerStyles: CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'table',
}

const textStyles: Partial<IStyleSet<ITextStyles>> = {
    root: {
        display: 'table-cell',
        verticalAlign: 'middle',
        textAlign: 'center',
    }
}

export const NoMatch: FunctionComponent = () => (
    <div style={containerStyles}>
        <Text variant="xxLarge" styles={textStyles}>404 Not found</Text>
    </div>
)
