import * as React from "react"
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { DocumentCard, DocumentCardDetails, DocumentCardImage, DocumentCardTitle, getTheme,
    ImageFit, IShimmerStyles, Label, MessageBar, MessageBarType, PrimaryButton, Shimmer, ShimmerElementsGroup,
    ShimmerElementType, Spinner, SpinnerSize, Stack, Text, Icon, DefaultButton } from "@fluentui/react"

import { DynamicTestCaseData, DynamicTestPeer, isSimulatedDeviceSpec, SimulatedDeviceSpec } from "./DynamicTest";
import { PeerSelectionModal } from "./PeerSelectionModal";
import { DEVICE_BY_ID, DYNAMIC_TEST_WITH_ID } from "../GraphQL/Queries";
import { Device, DeviceData, DeviceType, imageForDeviceType, isDevice } from "../Device";
import { getEnumKeyByEnumValue } from "../utils";
import { DynamicTestRun, PeerConfiguration } from "./DynamicTestRun";

const theme = getTheme();
const peerShimmerStyle: IShimmerStyles = {
    root: {
        display: "inline-block",
        marginTop: 20,
        marginRight: 20,
        width: 320,
    },
};

interface PeerProps {
    peer: DynamicTestPeer;
    selection: Device | SimulatedDeviceSpec | null,
    // Called when the selection changes.
    onConfigurationChanged: (config: Device | SimulatedDeviceSpec) => void;
}

const Peer: React.FunctionComponent<PeerProps> = (props) => {
    const imageHeight = 214
    const { peer, onConfigurationChanged } = props;

    // Select the type of the peer.
    const peerName = peer.name;
    let peerType: DeviceType | null = null;
    let image = "";
    let userCanSelectDevice = false;
    let loadedConfiguration: Device | SimulatedDeviceSpec | null = null;

    const { data, loading } = useQuery<DeviceData>(DEVICE_BY_ID, { variables: { dBId: peer.realDevice?.id } });

    if (peer.validDevices) {
        peerType = peer.validDevices;
        image = imageForDeviceType(peerType);
        userCanSelectDevice = true;
        // TODO: Handle the case that peer.simulatedDevice type does not match.
    } else if (peer.simulatedDevice) {
        const simulatedDevice = peer.simulatedDevice;
        peerType = simulatedDevice.simulationType;
        image = imageForDeviceType(peerType);
        loadedConfiguration = simulatedDevice;
    } else if (peer.realDevice) {
        if (!loading && data && data.devices.length > 0) {
            const device = data.devices[0];
            peerType = device.type;
            image = device.image ?? imageForDeviceType(peerType);
            loadedConfiguration = device;
        }
    }

    // If the configuration is fix, already tell the parent.
    React.useEffect(() => {
        if (!userCanSelectDevice && loadedConfiguration) {
            onConfigurationChanged(loadedConfiguration);
        }
    }, [userCanSelectDevice, loadedConfiguration, onConfigurationChanged, props.selection]);

    const [peerSelectionOpen, setPeerSelectionOpen] = React.useState(false);
    const peerSelectionClosed = (selection?: Device | SimulatedDeviceSpec) => {
        if (selection) {
            onConfigurationChanged(selection);
        }
        setPeerSelectionOpen(false);
    };

    return (
        <>
            <Shimmer
                styles={peerShimmerStyle}
                isDataLoaded={!!peerType}
                ariaLabel="Loading Peer..."
                customElementsGroup={
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <ShimmerElementsGroup
                            width="100%"
                            flexWrap
                            shimmerElements={[
                                { type: ShimmerElementType.line, width: "100%", height: imageHeight - 5 },
                            ]}
                        />
                        <ShimmerElementsGroup
                            width="100%"
                            flexWrap
                            shimmerElements={[
                                { type: ShimmerElementType.gap, width: "100%", height: 10 },
                            ]}
                        />
                        <ShimmerElementsGroup
                            width="100%"
                            flexWrap
                            shimmerElements={[
                                { type: ShimmerElementType.line, width: "100%", height: 289 - 10 - imageHeight + 5 },
                            ]}
                        />
                    </div>
                }
            >
                { peerType && (
                    <DocumentCard
                        styles={{
                            root: {
                                width: "100%",
                            }
                        }}
                        onClick={userCanSelectDevice ? () => setPeerSelectionOpen(true) : undefined}
                    >
                        <DocumentCardImage
                            height={imageHeight}
                            imageSrc={image}
                            imageFit={ImageFit.contain}
                        />
                        <DocumentCardDetails>
                            <DocumentCardTitle title={`${peer.name} (${getEnumKeyByEnumValue(DeviceType, peerType!)})`} shouldTruncate />
                        </DocumentCardDetails>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingBottom: 4,
                                paddingRight: 6,
                                height: "15px",
                            }}
                        >
                            <Icon
                                ariaLabel="Peer is set"
                                role="img"
                                iconName={props.selection || !userCanSelectDevice ? "Accept" : undefined}
                                style={{
                                    color: theme.palette.greenDark,
                                    fontWeight: "bolder",
                                    fontSize: "15px",
                                }}
                            />
                        </div>

                    </DocumentCard>
                )}
            </Shimmer>
            { peerName && peerType &&
                <div style={{position:"absolute"}} >
                    <PeerSelectionModal
                        peerName={peerName}
                        peerType={peerType}
                        isOpen={peerSelectionOpen}
                        selection={props.selection}
                        simulator={peer.simulatedDevice}
                        onClose={peerSelectionClosed}
                    />
                </div>
            }
        </>
    )
}

const START_TEST_RUN = gql`
    mutation StartDynamicTestCase($testCaseDbId: String!, $configs: [PeerConfigurationInput]!) {
        startDynamicTestCase(testCaseDbId: $testCaseDbId, configs: $configs) {
            testCaseDbId,
            testRunNumber,
        }
    }
`;
interface StartDynamicTestCaseResponse {
    startDynamicTestCase: DynamicTestRun,
}

export const _TestSpecializationDetails: React.FunctionComponent<RouteComponentProps> = (props) => {
    const { history } = props;

    let { testCaseDbId } = useParams<{testCaseDbId: string}>();
    const { loading, error, data } = useQuery<DynamicTestCaseData>(DYNAMIC_TEST_WITH_ID, {
        variables: {
            dBId: testCaseDbId,
        }
    });

    const found = data && data.dynamicTestCases.length;
    const testCase = found ? data!.dynamicTestCases[0] : null;

    // Holds the configuration for the peers.
    const [selectedPeers, setSelectedPeers] = React.useState<(Device | SimulatedDeviceSpec | null)[]>([]);
    const [allPeersSelected, setAllPeersSelected] = React.useState(false);

    React.useEffect(() => {
        if (testCase && selectedPeers.length === 0) {
            const emptyArray = Array.apply(null, Array(testCase?.peers.length || 0)).map(() => null);
            setSelectedPeers(emptyArray);
        }

        setAllPeersSelected(!!testCase && Object.keys(selectedPeers).length === testCase.peers.length);
    }, [selectedPeers, testCase]);

    const renderPeers = (peers: DynamicTestPeer[], selectedPeers: (Device | SimulatedDeviceSpec | null)[]) => {
        if (peers.length < 1) {
            return (<Text variant="medium" nowrap block>No Peers Available...</Text>)
        }
        return (
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                { selectedPeers.map((peer, idx) => (
                    <Peer
                        peer={peers[idx]}
                        key={peers[idx].name}
                        selection={peer}
                        onConfigurationChanged={(config) => {
                            const update = [...selectedPeers];
                            update[idx] = config;
                            setSelectedPeers(update);
                        }}
                    />
                ))}
            </div>
        );
    }

    // The run button shall be enabled when all configurable peers are configured.
    const [runButtonDisabled, setRunButtonDisabled] = React.useState(false);
    const [createError, setCreateError] = React.useState<string>();
    const [startTestRunMutation] = useMutation<StartDynamicTestCaseResponse>(START_TEST_RUN);
    const startTestRun = async (peers: DynamicTestPeer[]) => {
        try {
            setRunButtonDisabled(true);
            // Match the configurations back to the peers.
            const configs = selectedPeers.map<PeerConfiguration>((v, i) => {
                const peer = peers[i]
                return {
                    peerName: peer.name,
                    deviceId: isDevice(v) ? v.dBId : undefined,
                    isSimulator: isSimulatedDeviceSpec(v)
                }
            })

            // Call the mutation and start the test run.
            const result = await startTestRunMutation({
                variables: {
                    testCaseDbId: testCase!.dBId,
                    configs: configs,
                }
            });
            if (testCase && result.data) {
                const { testRunNumber } = result.data.startDynamicTestCase;
                history.push(`/test-specializations/${testCase.dBId}/${testRunNumber}`);
            }
        } catch (ex) {
            if (ex instanceof Error) {
                setCreateError(`${ex.name}: ${ex.message}`);
                return;
            }
            setCreateError(`${ex}`);
        } finally {
            setRunButtonDisabled(false);
        }
    }

    return (
        <Stack
            styles={{root: {padding: 20}}}
            tokens={{
                childrenGap: 15,
            }}
        >
            { loading &&
                <Spinner styles={{root: {padding: "20px"}}} size={SpinnerSize.large} /> }
            { !loading && (error || !data) &&
                <Text color="red" styles={{root: {margin: "20px"}}}>An error occurred. :(</Text>}
            { !loading && !error && testCase &&
                <>
                    <Text variant="xxLarge">
                        Test&nbsp;Case:&nbsp;{ testCase.name } ({ testCase.id })
                    </Text>
                    <Text variant="medium">
                        <Label>Authors:</Label>
                        { testCase.authors.join(', ') }
                    </Text>
                    <Text variant="medium">
                        <Label>Description:</Label>
                        { testCase.description }
                    </Text>

                    <Text variant="large">Peers</Text>
                    { createError &&
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            onDismiss={() => setCreateError(undefined)}
                            dismissButtonAriaLabel="Close"
                        >
                            {createError}
                        </MessageBar>
                    }
                    { renderPeers(testCase.peers, selectedPeers) }
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '2rem',
                        marginRight: '1rem'
                    }}>
                        <DefaultButton
                            text="Set all to Simuation"
                            style={{marginRight: 20}}
                            onClick={() => { setSelectedPeers(testCase.peers.map(x => x.simulatedDevice || null)) }} />
                        <PrimaryButton
                            text="Run"
                            disabled={!allPeersSelected || runButtonDisabled}
                            iconProps={{ iconName: "Play" }}
                            onClick={() => startTestRun(testCase.peers) } />
                    </div>
                </>
            }
        </Stack>
    )
}

export const TestSpecializationDetails = withRouter(_TestSpecializationDetails);
