import { DeviceType } from "../Device";
import { getEnumKeyByEnumValue } from "../utils";

export interface Interlocking {
    image: string,
}

export enum ConnectionType {
    Rasta = "rasta",
    RastaTls = "rasta_tls",
    ThalesCloud = "thales_cloud",
}

export interface RealDeviceSpec {
    id: string;
    connectionType?: ConnectionType;
}

export interface SimulatedDeviceSpec {
    image: string;
    simulationType: DeviceType;
}

export const isSimulatedDeviceSpec = (object: unknown): object is SimulatedDeviceSpec => {
    if (typeof object === 'undefined') {
        return false;
    }
    const potentialSimulatedDeviceSpec = object as SimulatedDeviceSpec
    return typeof potentialSimulatedDeviceSpec.image === 'string' &&
        getEnumKeyByEnumValue(DeviceType, potentialSimulatedDeviceSpec.simulationType) !== null
}

export interface DynamicTestPeer {
    name: string;
    localRastaId: number;
    localId: string;
    validDevices?: DeviceType;
    realDevice?: RealDeviceSpec;
    simulatedDevice?: SimulatedDeviceSpec;
}

export interface DynamicTest {
    dBId: string,
    name: string,
    authors: string[],
    id: string,
    description: string,
    interlocking: Interlocking,
    peers: DynamicTestPeer[],
}

export interface DynamicTestCaseData {
    dynamicTestCases: DynamicTest[],
}
