import React, { useEffect, useRef, CSSProperties, ClipboardEvent, FunctionComponent } from 'react'
import Convert from 'ansi-to-html'
import styles from './TextLog.module.scss'
import { css } from '@fluentui/react'

import { List, AutoSizer, ListRowRenderer, CellMeasurer, CellMeasurerCache } from 'react-virtualized'

export interface TextLogProps {
    containerStyle?: CSSProperties;
    logLines: string[];
    logStyle?: CSSProperties;
    follow?: boolean;
}

export const TextLog: FunctionComponent<TextLogProps> = (props) => {
    const convert = new Convert({ stream: false })

    const { containerStyle, logLines, logStyle, follow } = props

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 20,
    })

    const onCopy = (evt: ClipboardEvent<HTMLElement>): boolean => {
        evt.nativeEvent.stopImmediatePropagation();
        evt.preventDefault();
        evt.clipboardData.setData('text/plain', logLines.join('\n') )
        return false;
    }

    const preRef = useRef<HTMLPreElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (follow && containerRef.current && preRef.current) {
            containerRef.current.scrollTop = preRef.current.scrollHeight;
        }
    }, [follow, logLines]);

    const renderLine: ListRowRenderer = ({ index, key, parent, style }) => {
        return (
            <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            rowIndex={index}
            parent={parent}>
                {({measure, registerChild}) => (
                    <span
                        ref={(rf) => { if (registerChild && rf) registerChild(rf); }}
                        style={style}
                        onLoad={measure}
                    >
                        <span className={styles.logNumber} dangerouslySetInnerHTML={{ __html: `${index + 1}\n` }} unselectable='on'></span>
                        { index < logLines.length && <span dangerouslySetInnerHTML={{ __html: convert.toHtml(logLines[index] + '\n') }} /> }
                    </span>
                )}
            </CellMeasurer>
        )
    }

    return (
        <div className={styles.logContainer} style={containerStyle} ref={containerRef}>
            <pre className={css(styles.logText, styles.logTextContainer)} style={logStyle} onCopy={onCopy} ref={preRef}>
                <AutoSizer>{({ width, height }) => {
                    return <List
                        width={width}
                        height={height}
                        rowHeight={cache.rowHeight}
                        deferredMeasurementCache={cache}
                        rowRenderer={renderLine}
                        rowCount={logLines.length + 1}
                        overscanRowCount={100} />
                }}
                </AutoSizer>
            </pre>
        </div>
    )
}
