import * as React from 'react'
import { DocumentCard, DocumentCardPreview, DocumentCardTitle, ImageFit, makeStyles, Stack, Text } from '@fluentui/react'

import { Device } from './Device';

export interface DeviceListProps {
    devices: Device[];
    onDeviceClick: (device: Device, index: number) => void;
    enablePersistentSelection?: boolean;
    selectionIndex?: number;
}

export const useSelectedCardStyles = makeStyles((theme) => ({
    selectedCard: {
        // From: https://github.com/microsoft/fluentui/blob/dab45a2afe609709735b9b6e604a1af40d50e809/packages/react/src/components/DocumentCard/DocumentCard.styles.ts
        borderColor: `${theme.palette.accent}`,
        '& :after': {
            content: '" "',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            border: `1px solid ${theme.palette.neutralTertiaryAlt}`,
            pointerEvents: 'none',
        },
    }
}))

// TODO: This could probably be replaced by some kind of Choice Group in combination with FocusZone?
export const DeviceList: React.FunctionComponent<DeviceListProps> = (props) => {
    const { devices, onDeviceClick, enablePersistentSelection, selectionIndex } = props;

    const styles = useSelectedCardStyles();

    if (devices.length < 1) {
        return <Text>No devices found...</Text>
    }
    return (
        <Stack
            wrap
            styles={{root: {margin: '20px', width: '100%'}}}
            horizontal
            tokens={{
                childrenGap: 20,
            }}
        >
            {devices.map((d, idx) =>
            <DocumentCard
                key={d.name}
                onClick={() => onDeviceClick(d, idx)}
                className={(enablePersistentSelection && idx === selectionIndex) ? styles.selectedCard : undefined}
            >
                <DocumentCardPreview previewImages={[
                {
                    previewImageSrc: d.image,
                    imageFit: ImageFit.centerContain,
                    width: 318,
                    height: 196,
                },
                ]} />
                <DocumentCardTitle title={d.name} shouldTruncate />
            </DocumentCard>
            )}
        </Stack>
    );
}
DeviceList.defaultProps = {
    enablePersistentSelection: false,
}
