export interface Device {
    dBId: string,
    name: string,
    image: string,
    type: DeviceType,
    node: string,
    rastaConfig: RastaConfig,
}

export const isDevice = (object: unknown): object is Device => {
    if (typeof object === 'undefined') {
        return false;
    }
    const potentialDevice = object as Device

    return typeof potentialDevice.dBId === 'string' &&
        typeof potentialDevice.name === 'string' &&
        typeof potentialDevice.image === 'string' &&
        typeof potentialDevice.name === 'string' &&
        typeof potentialDevice.node === 'string' &&
        typeof potentialDevice.rastaConfig === 'object';
}

export interface DeviceData {
    devices: Device[],
}

export enum DeviceType {
    /// SCI-LS
    LightSignal = "ls",
    /// SCI-IO
    GenericIO = "io",
    /// SCI-P
    Point = "p",
    /// SCI-TDS
    TrainDetectionSystem = "tds",
    /// SCI-LC
    LevelCrosing = "lc",
    /// SCI-LX
    ExternalLevelCrossing = "lx",
    /// SCI-ILS
    AdjacentInterlockingSystem = "ils",
    /// SCI-CC
    TrafficControlSystem = "cc",
    /// SCI-RBC
    RadioBlockCentre = "rbc",
    /// SCI- TSS
    TrackworkerSafetySystem = "tss",
    Unknown = "unknown",
}

export const imageForDeviceType = (deviceType: DeviceType): string => {
    if (deviceType === DeviceType.LightSignal) {
        return "/test-case-images/light-signal.jpg";
    } else if (deviceType === DeviceType.Point) {
        return "/test-case-images/point.jpg";
    } else if (deviceType === DeviceType.TrainDetectionSystem) {
        return "/test-case-images/train-detection-system.jpg";
    } else if (deviceType === DeviceType.GenericIO) {
        return "/test-case-images/generic-io.jpg";
    }
    return "/test-case-images/unknown.jpg";
}

export interface RastaConfig {
    CrcCode: string,
    Host: string,
    Md4: string,
    Md4Start_00: string,
    Md4Start_01: string,
    Md4Start_02: string,
    Md4Start_03: string,
    MWA: number,
    NDeferQueueSize: number,
    NDiagnosis: number,
    NDiagWindow: number,
    NMaxPacket: number,
    NSendmax: number,
    RastaLocalID: string,
    RastaNetwork: string,
    RastaRemoteID: string,
    RastaVersion: string,
    ReceiverID: string,
    Th: number,
    Tmax: number,
    LocalPortChannel0: number,
    RemotePortChannel0: number,
    LocalPortChannel1: number,
    RemotePortChannel1: number,
    TransportCnt: number,
    TSeq: number,
    SenderID: string,
}
