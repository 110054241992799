import * as React from 'react'
import { DefaultButton, IIconProps, css, AnimationStyles, makeStyles } from '@fluentui/react'
import styles from './Accordion.module.scss'

export interface AccordionProps {
    defaultCollapsed?: boolean;
    title: string;
    className?: string;
}

const animationStyles = makeStyles(() => ({
    openAnimation: {
        ...AnimationStyles.slideDownIn20,
    },
    closed: {
        display: 'none',
    },
}))

export const Accordion: React.FunctionComponent<React.PropsWithChildren<AccordionProps>> = (props) => {
    const {title, defaultCollapsed, children} = props;
    const aStyles = animationStyles();

    const collapsedIcon: IIconProps = {
        iconName: 'ChevronRight',
        className: styles.accordionChevron
    };
    const expandedIcon: IIconProps = {
        iconName: 'ChevronDown',
        className: styles.accordionChevron
    };

    const [expanded, setExpanded] = React.useState(!defaultCollapsed);

    return (
        <div className={styles.accordion}>
            <DefaultButton
                toggle
                checked={expanded}
                text={title}
                iconProps={expanded ? expandedIcon : collapsedIcon}
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded} />
            {
                <div className={css(styles.drawer, expanded && aStyles.openAnimation, !expanded && aStyles.closed)}>
                    {children}
                </div>
            }
        </div>
    )

}
