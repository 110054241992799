import { Stack } from '@fluentui/react';
import React from 'react'
import { InterlockingUI, OverallPlacement, Topology } from 'interlocking-ui';

export class InteractiveDemo extends React.Component<{}, {topo?: Topology, webSocket?: WebSocket, interlockingState?: {[key:string]: string}, placement?: OverallPlacement}> {

  async componentDidMount() {

    try {
      const response = await fetch('https://lab.eulynx.live/device-io/scheibenberg-scenarios-4573-lab/interlocking/Topology');
      this.setState({topo: await response.json() as Topology });
    } catch {
    }

    try {
      const response = await fetch('https://lab.eulynx.live/device-io/scheibenberg-scenarios-4573-lab/interlocking/Topology/Placement');
      this.setState({placement: await response.json() as OverallPlacement });
    } catch {
    }

    const ws = new WebSocket('wss://lab.eulynx.live/device-io/scheibenberg-scenarios-4573-lab/interlocking/ws');
    this.setState({
        webSocket: ws,
    })
    ws.onopen = (event) => {
        console.log("connected");
    }
    ws.onerror = (error) => {
        console.error(error);
    }
    ws.onmessage = (event) => {
        this.receiveMessage(event);
    };
  }

  receiveMessage(event: MessageEvent) {
    if (typeof event.data !== 'string') {
        return;
    }
    const state = JSON.parse(event.data) as {
      "id": string | null,
      "states": {[key:string]: string},
      "validTransitions": null
    };
    this.setState({interlockingState: state.states});
  }

  render() {
    if (this.state && this.state.topo && this.state.placement) {
      return (
        <Stack horizontal verticalFill>
          <InterlockingUI renderPointStates={true} topology={this.state.topo} showDeviceIO={true} interlockingState={this.state.interlockingState} placement={this.state.placement} />
        </Stack>
      );
    }
    return (<></>);
  }
}
