import { gql } from '@apollo/client';

export const LOCATIONS = gql`
query Locations {
  locations {
    name
    image
  }
}
`;

export const DEVICES = gql`
query Devices {
  devices(order: { name: ASC }) {
    dBId
    name
    image
    type
    node
    rastaConfig {
      crcCode
      host
      md4
      md4Start_00
      md4Start_01
      md4Start_02
      md4Start_03
      mWA
      nDeferQueueSize
      nDiagnosis
      nDiagWindow
      nMaxPacket
      nSendmax
      rastaLocalID
      rastaNetwork
      rastaRemoteID
      rastaVersion
      receiverID
      th
      tmax
      localPortChannel0
      remotePortChannel0
      localPortChannel1
      remotePortChannel1
      transportCnt
      tSeq
      senderID
    }
  }
}
`;

export const DEVICES_FILTER = gql`
query Devices($typeFilter: DeviceType) {
  devices(order: { name: ASC }, where: { type: { eq: $typeFilter } }) {
    dBId
    name
    image
    type
    node
    rastaConfig {
      crcCode
      host
      md4
      md4Start_00
      md4Start_01
      md4Start_02
      md4Start_03
      mWA
      nDeferQueueSize
      nDiagnosis
      nDiagWindow
      nMaxPacket
      nSendmax
      rastaLocalID
      rastaNetwork
      rastaRemoteID
      rastaVersion
      receiverID
      th
      tmax
      localPortChannel0
      remotePortChannel0
      localPortChannel1
      remotePortChannel1
      transportCnt
      tSeq
      senderID
    }
  }
}
`;

export const DEVICE_BY_ID = gql`
query Devices($dBId: String!) {
  devices(order: { name: ASC }, where: { dBId: { eq: $dBId } }) {
    dBId
    name
    image
    type
    node
    rastaConfig {
      crcCode
      host
      md4
      md4Start_00
      md4Start_01
      md4Start_02
      md4Start_03
      mWA
      nDeferQueueSize
      nDiagnosis
      nDiagWindow
      nMaxPacket
      nSendmax
      rastaLocalID
      rastaNetwork
      rastaRemoteID
      rastaVersion
      receiverID
      th
      tmax
      localPortChannel0
      remotePortChannel0
      localPortChannel1
      remotePortChannel1
      transportCnt
      tSeq
      senderID
    }
  }
}
`

export const TESTSTEPS = gql`
query test($testcaseId: String!) {
  teststeps (order: { tcStepNumber:ASC }, where:{ tcDbId: { eq: $testcaseId }}) {
    dbId
    tcDbId
    tcStepNumber
    tsApplicability
    tsCoverage
    instance
    if
    dir
    name
    description
    remarks
    formalisation
    timing
    reactionBlock
    trigger
  }
}
`;

export const TESTCASES = gql`
query Test {
  testcases {
    dbId
    tcVersionMainMajor
    tcVersionMainMinor
    tcVersionLabMinor
    tcVersionLabCampaign
    tcApplicability
    tcCoverage
    focus
    name
    description
    remarks
    type
    siteData
    author
    state
  }
}
`;

export const TEST_CASE_GQL_CONTENT = `
dBId
name
authors
id
description
interlocking {
  image
}
peers {
  name
  localRastaId
  localId
  validDevices
  realDevice {
    id
    connectionType
  }
  simulatedDevice {
    image
    simulationType
  }
}
`;

export interface PlanningProject {
  id: string
  name: string
  author: string
  description: string
  version: string
};
export interface PlanningProjectsData { planProProjects: PlanningProject[]}
export const PLANNING_PROJECTS = gql`
query PlanProProjects {
  planProProjects {
    id
    name
    author
    description
    version
  }
}
`;

export const CREATE_TEST_CASE = gql`
mutation createTestCase($id: String, $planningProjectId: String!, $parameters: Any) {
  createDynamicTestCase(testcaseId: $id, planProProjectId: $planningProjectId, parameters: $parameters) {
    dBId
  }
}`

export const DYNAMIC_TESTS = gql`
query DynamicTest {
  dynamicTestCases {
    ${TEST_CASE_GQL_CONTENT}
  }
}
`;

/**
 * Find a test case by the internally used database id.
 * @param dBId The internal UID of the test in K8S.
 */
export const DYNAMIC_TEST_WITH_ID = gql`
query DynamicTest($dBId: String!) {
  dynamicTestCases(where:{ dBId: { eq: $dBId }}) {
    ${TEST_CASE_GQL_CONTENT}
  }
}
`;

export const PEER_CONFIGURATION_GQL_CONTENT = `
peerName
isSimulator
deviceId
`;

export const DYNAMIC_TEST_RUN_GQL_CONTENT = `
id
testCaseDbId
testRunNumber
running
exitCode
startDate
updateDate
ioFrames
testCase {
  ${TEST_CASE_GQL_CONTENT}
}
configurations {
  ${PEER_CONFIGURATION_GQL_CONTENT}
}
`;

/**
 * Find all dynamic test runs.
 */
export const ALL_DYNAMIC_TEST_RUNS = gql`
query DynamicTestRun {
  allDynamicTestRuns {
    ${DYNAMIC_TEST_RUN_GQL_CONTENT}
  }
}
`;

/**
 * Find test runs for a specific test case.
 * @param dBId The internal UID of the test case in K8S.
 */
export const DYNAMIC_TEST_RUNS = gql`
query DynamicTestRun($dBId: String!) {
  dynamicTestRuns(testCaseDbId: $dBId) {
    ${DYNAMIC_TEST_RUN_GQL_CONTENT}
  }
}
`;

export const SPECIFIC_DYNAMIC_TEST_RUN = gql`
query DynamicTestRun($testCaseDbId: String!, $testRunNumber: Long!) {
  dynamicTestRuns(testCaseDbId: $testCaseDbId, where: { testRunNumber: { eq: $testRunNumber } }) {
    ${DYNAMIC_TEST_RUN_GQL_CONTENT}
  }
}
`;


export const DYNAMIC_TEST_RUN_LOGS = gql`
query DynamicTestRunLogs($testRunId: String!) {
  dynamicTestRunLogs(testRunId: $testRunId) {
    logs {
      key
      value
    }
  }
}
`;

export const DYNAMIC_TEST_RUN_CONTROL_URLS = gql`
query DynamicTestRunControlUrls($testRunId: String!) {
  dynamicTestRunControlUrls(testRunId: $testRunId) {
    job
    url
  }
}
`;
