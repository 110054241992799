import React, { FunctionComponent } from 'react'
import {
    CheckboxVisibility,
    DetailsHeader,
    DetailsListLayoutMode,
    IDetailsHeaderProps,
    IDetailsListStyles,
    IGroup,
    ISpinnerStyles,
    ITextStyles,
    ShimmeredDetailsList,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
} from '@fluentui/react';
import { useQuery } from '@apollo/client';
import { ALL_DYNAMIC_TEST_RUNS, DYNAMIC_TESTS } from '../GraphQL/Queries';
import { DynamicTestCaseData } from './DynamicTest';
import { DynamicTestRun, AllDynamicTestRunData } from './DynamicTestRun';
import { useHistory } from 'react-router-dom';

export interface ITestRunListItem {
    key: string;
    number: string;
    id: string;
    testCaseDbId: string;
    running: boolean;
    exitCode: number | null;
}

const isTestRunListItem = (obj: unknown): obj is ITestRunListItem => {
    const potentialListItem = obj as ITestRunListItem;
    return typeof potentialListItem.key === 'string' &&
        typeof potentialListItem.number === 'string' &&
        typeof potentialListItem.id === 'string' &&
        typeof potentialListItem.testCaseDbId === 'string' &&
        typeof potentialListItem.running === 'boolean';
};

const columns = [
    { key: 'number', name: '#', fieldName: 'number', minWidth: 50, maxWidth: 100, isResizable: true },
    { key: 'id', name: 'Id', fieldName: 'id', minWidth: 100, maxWidth: 200, isResizable: true },
    { key: 'running', name: 'Running?', fieldName: 'running', minWidth: 100, maxWidth: 200, isResizable: true },
    { key: 'exitcode', name: 'ExitCode', fieldName: 'exitCode', minWidth: 100, maxWidth: 200, isResizable: true },
];

const listStyle: Partial<IDetailsListStyles> = {
    root: {
        maxHeight: '85vh',
    }
}

interface ITestRunListProps {
    loading: boolean,
    groups: IGroup[] | undefined,
    items: ITestRunListItem[] | undefined,
}

const TestRunList: FunctionComponent<ITestRunListProps> = (props) => {
    const { items, groups, loading } = props;

    const history = useHistory();
    const onRenderDetailsHeader = (props?: IDetailsHeaderProps) => {
        if (!props) {
            return null;
        }
        return <DetailsHeader {...props} ariaLabelForToggleAllGroupsButton={'Expand collapse groups'} />;
    }

    const itemInvoked = (item?: any) => {
        if (!isTestRunListItem(item)) {
            console.error("Invalid item clicked.", item);
            return;
        }
        history.push(`/test-specializations/${item.testCaseDbId}/${item.number}`);
    }

    return (
        <ShimmeredDetailsList
            enableShimmer={loading}
            styles={listStyle}
            items={items || []}
            groups={groups}
            columns={columns}
            layoutMode={DetailsListLayoutMode.justified}
            checkboxVisibility={CheckboxVisibility.hidden}
            onRenderDetailsHeader={onRenderDetailsHeader}
            onItemInvoked={itemInvoked}
        />
    );
};

const spinnerStyle: Partial<ISpinnerStyles> = {
    root: {
        width: '100%',
        margin: '0 auto',
    },
};

const noResultsStyle: Partial<ITextStyles> = {
    root: {
        width: '100%',
        textAlign: 'center',
        marginTop: '1rem',
    },
};

export const TestSpecializationRunResults: FunctionComponent = () => {
    const { loading: loadingTestRuns, error: errorTestRuns, data: dataTestRuns } = useQuery<AllDynamicTestRunData>(ALL_DYNAMIC_TEST_RUNS, {
        fetchPolicy: 'no-cache',
    });
    const { loading: loadingTestCases, error: errorTestCases, data: dataTestCases } = useQuery<DynamicTestCaseData>(DYNAMIC_TESTS, {
        fetchPolicy: 'no-cache',
    });

    const sortedTestRuns: DynamicTestRun[] | undefined = !loadingTestRuns && !errorTestRuns && !!dataTestRuns?.allDynamicTestRuns
        ? dataTestRuns.allDynamicTestRuns.slice()
            .sort((a, b) => {
                if (a.testCaseDbId < b.testCaseDbId) {
                    return -1;
                } else if (a.testCaseDbId > b.testCaseDbId) {
                    return 1;
                }
                return b.testRunNumber - a.testRunNumber
            })
        : undefined;
    const items: ITestRunListItem[] | undefined = sortedTestRuns && sortedTestRuns.map((testRun) => ({
        key: testRun.id,
        number: `${testRun.testRunNumber}`,
        id: testRun.id,
        testCaseDbId: testRun.testCaseDbId,
        running: testRun.running,
        exitCode: testRun.exitCode,
    }));

    const indexMap: Record<string, { start: number, count: number }> | undefined = sortedTestRuns && sortedTestRuns.reduce((acc, testRun, idx) => {
        const testCaseId = testRun.testCaseDbId;
        if (!acc[testCaseId]) {
            acc[testCaseId] = {
                start: idx,
                count: 1,
            };
            return acc;
        }
        acc[testCaseId].count = idx - acc[testCaseId].start + 1;
        return acc
    }, {} as Record<string, { start: number, count: number }>);

    const groups: IGroup[] | undefined = !loadingTestCases && !errorTestCases && !!dataTestCases && indexMap
        ? dataTestCases!.dynamicTestCases.map<IGroup>((tc) => ({
            key: tc.dBId,
            name: `${tc.name} ${tc.id}`,
            startIndex: indexMap[tc.dBId]?.start || 0,
            count: indexMap[tc.dBId]?.count || 0,
        })).filter((g) => g.count > 0)
        : undefined;

    const isLoading = (loadingTestCases || loadingTestRuns);
    const hasError = (!!errorTestCases || !!errorTestRuns);

    return (
        <Stack
            styles={{root: {padding: '20px'}}}
            tokens={{
                childrenGap: 10,
            }}
        >
            <Text variant="xxLarge">Test Results</Text>
            <Text variant="large">
                Here, the test results of Test Specialization Runs are linked.
            </Text>
            { isLoading && <Spinner size={SpinnerSize.large} styles={spinnerStyle} /> }
            { hasError &&
                <Text variant="large" styles={noResultsStyle}>Error loading results</Text> }
            { !isLoading && !hasError && items && items.length === 0 &&
                <Text variant="large" styles={noResultsStyle}>No results</Text> }
            { !hasError && !(!isLoading && items?.length === 0) &&
                <TestRunList loading={isLoading} groups={groups} items={items} /> }
        </Stack>
    );
};
